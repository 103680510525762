import { Record } from "../fable_modules/fable-library-js.4.16.0/Types.js";
import { record_type, int32_type } from "../fable_modules/fable-library-js.4.16.0/Reflection.js";
import { State, StatusArea_Msg, StatusArea_Message, StatusArea_Level } from "../Base/State.fs.js";
import { ofArrayWithTail, cons, mapIndexed, filter, length, empty as empty_1, tryFind, map, singleton, ofArray } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { PlanValue, ImportEmissionSourcesFile, Msg } from "../Base/Msg.fs.js";
import { Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library-js.4.16.0/Async.js";
import { ScopeEmissions, EmissionSource, EmissionSourceIdModule_create } from "../../Shared/BaseTypes/Emissions.fs.js";
import { empty } from "../fable_modules/fable-library-js.4.16.0/Map.js";
import { safeHash, equals, compare } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { List_groupBy } from "../fable_modules/fable-library-js.4.16.0/Seq2.js";
import { Scope_all, ScopeValues_get, ScopeValues_map, ScopeValues_mapi } from "../../Shared/BaseTypes/ScopeHelpers.fs.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library-js.4.16.0/Option.js";
import { List_addOrUpdate } from "../UIHelpers.fs.js";
import { UserData as UserData_1 } from "../../Shared/Database/UserData.fs.js";
import { Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.16.0/String.js";

export class OpCounts extends Record {
    constructor(Adds, Updates) {
        super();
        this.Adds = (Adds | 0);
        this.Updates = (Updates | 0);
    }
}

export function OpCounts_$reflection() {
    return record_type("AccelerateGreen.WebUI.View.Import.OpCounts", [], OpCounts, () => [["Adds", int32_type], ["Updates", int32_type]]);
}

export function importEmissionSourcesFile(backend, import$, state) {
    let bind$0040_1;
    switch (import$.tag) {
        case 2: {
            const message_1 = new StatusArea_Message(new StatusArea_Level(1, []), ofArray(["Emissionsquellen konnten nicht importiert werden:", import$.fields[0]]), 0);
            return [state, singleton((dispatch_1) => {
                dispatch_1(new Msg(12, [new StatusArea_Msg(0, [message_1])]));
            })];
        }
        case 0:
            return [state, Cmd_OfAsyncWith_either((computation) => {
                startImmediate(computation);
            }, backend.uploadEmissionSourcesFile, import$.fields[0], (_arg_4) => {
                if (_arg_4.tag === 1) {
                    return new Msg(7, [new ImportEmissionSourcesFile(2, [_arg_4.fields[0]])]);
                }
                else {
                    return new Msg(7, [new ImportEmissionSourcesFile(1, [_arg_4.fields[0]])]);
                }
            }, (Item) => (new Msg(10, [Item])))];
        default: {
            const sources = map((tupledArg) => [tupledArg[0], map((s) => (new EmissionSource(EmissionSourceIdModule_create(), s.Name, 3, s.FootPrint, empty({
                Compare: compare,
            }), "")), tupledArg[1])], List_groupBy((_arg) => _arg.Scope, import$.fields[0], {
                Equals: equals,
                GetHashCode: safeHash,
            }));
            const emissions = state.UserData.Emissions;
            const results = ScopeValues_mapi((scope_1, list_4) => {
                let newItems_1, updates;
                const newItems = defaultArg(map_1((tuple) => tuple[1], tryFind((tupledArg_1) => equals(tupledArg_1[0], scope_1), sources)), empty_1());
                const result = List_addOrUpdate((src) => src.Name, newItems, list_4);
                return [result, (newItems_1 = (length(newItems) | 0), (updates = (((length(list_4) + newItems_1) - length(result)) | 0), new OpCounts(newItems_1 - updates, updates)))];
            }, ScopeValues_map((_arg_2) => _arg_2.Sources, emissions));
            const newEmissions = ScopeValues_mapi((scope_2, v) => {
                const bind$0040 = ScopeValues_get(scope_2, emissions);
                return new ScopeEmissions(bind$0040.BaseYearEmissions, v, bind$0040.ReductionPlan);
            }, ScopeValues_map((tuple_1) => tuple_1[0], results));
            const summary = ScopeValues_map((tuple_2) => tuple_2[1], results);
            return [new State(state.Storage, state.IsServerReachable, state.TargetType, state.ScopeSelection, state.UserParameters, (bind$0040_1 = state.UserData, new UserData_1(bind$0040_1.TimeLine, bind$0040_1.BaseYear, newEmissions)), state.ShowAllScopes, state.FocusedEmissionSource, state.StatusArea), Cmd_batch(map((msg) => singleton((dispatch) => {
                dispatch(msg);
            }), ofArrayWithTail([new Msg(12, [new StatusArea_Msg(0, [new StatusArea_Message(new StatusArea_Level(0, []), cons("Emissionsquellen wurden erfolgreich importiert:", map((tupledArg_3) => {
                const arg_2 = (tupledArg_3[0] + 1) | 0;
                let arg_3;
                const ops = tupledArg_3[1];
                arg_3 = toText(printf("%d aktualisierte und  %d neue Einträge."))(ops.Updates)(ops.Adds);
                return toText(printf("- Scope %d: %s"))(arg_2)(arg_3);
            }, filter((tupledArg_2) => {
                const ops_1 = tupledArg_2[1];
                if (ops_1.Adds > 0) {
                    return true;
                }
                else {
                    return ops_1.Updates > 0;
                }
            }, mapIndexed((idx, scope_3) => [idx, ScopeValues_get(scope_3, summary)], Scope_all)))), 0)])]), new Msg(5, [true])], map((scope_4) => (new Msg(6, [scope_4, new PlanValue(3, [])])), Scope_all))))];
        }
    }
}

