import { StateModule_insertSavedState, StateModule_extractSavedState, Storage, State } from "../Base/State.fs.js";
import { defaultArg, map, value as value_30 } from "../fable_modules/fable-library-js.4.16.0/Option.js";
import { ofArray, map as map_1, singleton, isEmpty, exists, tryHead, find } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { Option_fromString } from "../UIHelpers.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library-js.4.16.0/Async.js";
import { EmissionSourceIdModule_Last_set, EmissionSourceIdModule_Last_get } from "../../Shared/BaseTypes/Emissions.fs.js";
import { Msg, StateStorage } from "../Base/Msg.fs.js";
import { createElement } from "react";
import { format } from "../fable_modules/fable-library-js.4.16.0/String.js";
import { toString } from "../fable_modules/fable-library-js.4.16.0/Date.js";
import { equals, createObj } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.16.0/Seq.js";

export function update(backend, msg, state) {
    let tupledArg, matchValue_1, name_3;
    const storage = state.Storage;
    const update_1 = (newStorage) => (new State(newStorage, state.IsServerReachable, state.TargetType, state.ScopeSelection, state.UserParameters, state.UserData, state.ShowAllScopes, state.FocusedEmissionSource, state.StatusArea));
    const getSelected = () => {
        const name = value_30(storage.Selection);
        return find((si) => (si.Name === name), storage.StateInfos);
    };
    switch (msg.tag) {
        case 1:
            return [update_1(new Storage(storage.Name, storage.LastChange, storage.StateInfos, Option_fromString(msg.fields[0]))), Cmd_none()];
        case 6:
            return [state, Cmd_OfAsyncWith_either((computation) => {
                startImmediate(computation);
            }, backend.saveState, (tupledArg = [state, EmissionSourceIdModule_Last_get()], StateModule_extractSavedState(tupledArg[0], tupledArg[1])), () => (new Msg(9, [new StateStorage(2, [])])), (Item) => (new Msg(10, [Item])))];
        case 4:
            return [state, (storage.Selection == null) ? Cmd_none() : Cmd_OfAsyncWith_either((computation_1) => {
                startImmediate(computation_1);
            }, backend.loadState, getSelected(), (arg_1) => (new Msg(9, [new StateStorage(5, [arg_1])])), (Item_3) => (new Msg(10, [Item_3])))];
        case 5: {
            const patternInput = StateModule_insertSavedState(msg.fields[0], state);
            EmissionSourceIdModule_Last_set(patternInput[1]);
            return [patternInput[0], Cmd_none()];
        }
        case 2:
            return [state, Cmd_OfAsyncWith_either((computation_2) => {
                startImmediate(computation_2);
            }, backend.getListOfSavedStates, void 0, (arg_3) => (new Msg(9, [new StateStorage(3, [arg_3])])), (Item_6) => (new Msg(10, [Item_6])))];
        case 3: {
            const list_1 = msg.fields[0];
            return [update_1(new Storage(storage.Name, storage.LastChange, list_1, (matchValue_1 = state.Storage.Selection, (matchValue_1 == null) ? map((_arg_1) => _arg_1.Name, tryHead(list_1)) : ((name_3 = matchValue_1, exists((x) => (x.Name === name_3), list_1) ? name_3 : void 0))))), Cmd_none()];
        }
        case 7:
            return [state, Cmd_OfAsyncWith_either((computation_3) => {
                startImmediate(computation_3);
            }, backend.deleteState, getSelected(), () => (new Msg(9, [new StateStorage(2, [])])), (Item_7) => (new Msg(10, [Item_7])))];
        default:
            return [update_1(new Storage(msg.fields[0], storage.LastChange, storage.StateInfos, storage.Selection)), Cmd_none()];
    }
}

export function buildInfoList(list) {
    if (isEmpty(list)) {
        return singleton(createElement("option", {
            children: "-",
            value: "",
        }));
    }
    else {
        return map_1((info) => createElement("option", {
            children: format("{0} ({1})", info.Name, toString(info.Creation, "dd.MM.yyyy, HH:mm")),
            value: info.Name,
        }), list);
    }
}

export function render(storage, dispatch) {
    let elems_1, children, value_5, elems, children_2, value_22;
    return createElement("div", createObj(ofArray([["className", "storage"], (elems_1 = [(children = ofArray([createElement("label", {
        children: "Gespeichert:",
    }), createElement("select", createObj(ofArray([(value_5 = defaultArg(storage.Selection, ""), ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_5)) {
            e.value = value_5;
        }
    }]), ["onChange", (ev) => {
        dispatch(new StateStorage(1, [ev.target.value]));
    }], (elems = buildInfoList(storage.StateInfos), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("button", createObj(toList(delay(() => append(singleton_1(["children", "Laden"]), delay(() => append(singleton_1(["onClick", (_arg) => {
        dispatch(new StateStorage(4, []));
    }]), delay(() => ((storage.Selection == null) ? singleton_1(["disabled", true]) : empty()))))))))), createElement("button", createObj(toList(delay(() => append(singleton_1(["children", "Löschen"]), delay(() => append(singleton_1(["onClick", (_arg_1) => {
        dispatch(new StateStorage(7, []));
    }]), delay(() => ((storage.Selection == null) ? singleton_1(["disabled", true]) : empty())))))))))]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = ofArray([createElement("label", {
        children: "Geladen:",
    }), createElement("input", createObj(ofArray([["onChange", (ev_1) => {
        dispatch(new StateStorage(0, [ev_1.target.value]));
    }], (value_22 = storage.Name, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals(e_1.value, value_22)) {
            e_1.value = value_22;
        }
    }])]))), createElement("button", createObj(toList(delay(() => append(singleton_1(["children", "Speichern"]), delay(() => append(singleton_1(["onClick", (_arg_2) => {
        dispatch(new StateStorage(6, []));
    }]), delay(() => ((storage.Name === "") ? singleton_1(["disabled", true]) : empty())))))))))]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

