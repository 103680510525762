import { ScopeSelectionValues$1, ScopeValues$1, Scope } from "./Scope.fs.js";
import { printf, toFail } from "../../Client/fable_modules/fable-library-js.4.16.0/String.js";
import { singleton, ofArray } from "../../Client/fable_modules/fable-library-js.4.16.0/List.js";

export function Scope_fromInt(_arg) {
    switch (_arg) {
        case 1:
            return new Scope(0, []);
        case 2:
            return new Scope(1, []);
        case 3:
            return new Scope(2, []);
        default:
            return toFail(printf("Unknown scope \'%d\'."))(_arg);
    }
}

export function Scope_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 2;
        case 2:
            return 3;
        default:
            return 1;
    }
}

export const Scope_all = ofArray([new Scope(0, []), new Scope(1, []), new Scope(2, [])]);

export function ScopeValues_get(s, sv) {
    switch (s.tag) {
        case 1:
            return sv.Scope2;
        case 2:
            return sv.Scope3;
        default:
            return sv.Scope1;
    }
}

export function ScopeValues_set(s, newValue, sv) {
    switch (s.tag) {
        case 1:
            return new ScopeValues$1(sv.Scope1, newValue, sv.Scope3);
        case 2:
            return new ScopeValues$1(sv.Scope1, sv.Scope2, newValue);
        default:
            return new ScopeValues$1(newValue, sv.Scope2, sv.Scope3);
    }
}

export function ScopeValues_map(mapper, sv) {
    return new ScopeValues$1(mapper(sv.Scope1), mapper(sv.Scope2), mapper(sv.Scope3));
}

export function ScopeValues_mapi(mapper, sv) {
    return new ScopeValues$1(mapper(new Scope(0, []), sv.Scope1), mapper(new Scope(1, []), sv.Scope2), mapper(new Scope(2, []), sv.Scope3));
}

export function ScopeSelection_getList(s, v) {
    switch (s) {
        case 0:
            return singleton([new Scope(0, []), v.Scope1]);
        case 1:
            return singleton([new Scope(1, []), v.Scope2]);
        case 2:
            return ofArray([[new Scope(0, []), v.Scope1], [new Scope(1, []), v.Scope2]]);
        case 3:
            return singleton([new Scope(2, []), v.Scope3]);
        default:
            return toFail(printf("Unknown ScopeSelection: %A."))(s);
    }
}

export function ScopeSelection_getAll(v) {
    return ofArray([[new Scope(0, []), v.Scope1], [new Scope(1, []), v.Scope2], [new Scope(2, []), v.Scope3]]);
}

export function ScopeSelectionValues_get(selection, values) {
    switch (selection) {
        case 0:
            return values.S1;
        case 1:
            return values.S2;
        case 2:
            return values.S12;
        case 3:
            return values.S3;
        default:
            throw new Error("Unknown scope selection.");
    }
}

export function ScopeSelectionValues_set(selection, values, value) {
    switch (selection) {
        case 0:
            return new ScopeSelectionValues$1(value, values.S2, values.S3, values.S12);
        case 1:
            return new ScopeSelectionValues$1(values.S1, value, values.S3, values.S12);
        case 2:
            return new ScopeSelectionValues$1(values.S1, values.S2, values.S3, value);
        case 3:
            return new ScopeSelectionValues$1(values.S1, values.S2, value, values.S12);
        default:
            throw new Error("Unknown scope selection.");
    }
}

