import { singleton, ofArray } from "../fable_modules/fable-library-js.4.16.0/List.js";

export const scopeSelectionNames = ofArray([[0, "1"], [1, "2"], [2, "1 + 2"], [3, "3"]]);

export const targetTypeNames = ofArray([[0, "Potenzial-Ansatz"], [1, "SBTi-Standardansatz"], [2, "SBTi-KMU-Ansatz"]]);

export const difficultyNames = ofArray([[1, "1"], [2, "2"], [3, "3"]]);

export const scopes = ofArray(["Die Treibhausgasemissionen, die mit dem Corporate Carbon Footprint eines Unternehmens in Verbindung stehen, werden vom GHG Protocol in drei Kategorien eingeteilt:", "- Scope 1 Emissionen sind direkte Emissionen, die von einem Unternehmen selbst verursacht werden, z. B. durch Verbrennung von Brennstoffen in eigenen Anlagen oder Fahrzeugen.", "- Scope 2 Emissionen sind indirekte Emissionen, die durch den Kauf und Verbrauch von Strom, Wärme oder Dampf entstehen.", "- Scope 3 Emissionen sind alle anderen indirekten Emissionen, die durch Aktivitäten entstehen, die nicht direkt vom Unternehmen kontrolliert werden, wie z. B. Emissionen aus der Produktion von Rohstoffen oder aus der Entsorgung von Abfallprodukten."]);

export const baseYear = singleton("Als Basisjahr ist dasjenige Jahr zu wählen, für welches die aktuellsten Daten verfügbar sind.\n        Die aktuellsten Daten sollen dabei nicht älter als zwei Jahre vor dem Eingabejahr sein\n        (d.h. für das Eingabejahr 2023 muss ein THG-Inventar nicht älter als von 2021 vorliegen).\n        Für Kurz- und Langfristziele ist das gleiche Basisjahr zu wählen.");

export const sbtiDescription = "Die Science Based Targets Initiative (SBTi) ist eine weltweit breit anerkannte Methode zur Festlegung von Dekarbonisierungszielen für Unternehmen.\n        Die SBTi-Methodik ist ein Top-down-Ansatz und geht von einer Aussenperspektive aus. Treibhausgas-Reduktionsziele auf Basis der SBTi-Methodik\n        entsprechen dem Grad der Dekarbonisierung, die erforderlich ist, um die Ziele des Pariser Klimaabkommens zu erreichen, nämlich die globale\n        Erwärmung auf deutlich unter 2 Grad über dem vorindustriellen Niveau zu limitieren und die Bemühungen zur Begrenzung der Erwärmung\n        auf 1,5 Grad fortzusetzen.";

export const AbsoluteContraction_description = ofArray([sbtiDescription, "", "Der SBTi Standard-Ansatz basiert auf den folgenden Prinzipien:", "- Anwendbarkeit: Für alle Industriesektoren ausser emissionsintensiven Sektoren wie Energieerzeugung, Seeverkehr, Forest, land-use & agriculture (FLAG).", "- Die Ziele müssen alle relevanten Emissionen aus Scope 1, 2 und 3 abdecken.", "- Die Ziele müssen regelmässig überprüft und aktualisiert werden, um den neuesten Erkenntnissen zu entsprechen.", "- In den Informationsboxen weiter unten finden Sie Detail-Informationen zum SBTi Standard-Ansatz in Bezug auf die folgenden Themen:\n            Scopes 1-3, Basisjahr, Kurzfrist-Zielsetzung und Langfrist-Zielsetzung (Pro Zielsetzung: Beschreibung, min. Abdeckungsgrad, min. Reduktionsziel)."]);

export const AbsoluteContraction_ShortTerm_targetYear = ofArray(["1) Beim Standard-Ansatz müssen die Ziele einen Zeitraum umfassen von mindestens fünf und maximal 10 Jahren *nach dem Eingabejahr* für die Validierung durch SBTi.", "2) Unternehmen, die in einem Zeitrahmen von mehr als 10 Jahren einen Zustand von Netto-Null-Emissionen erreichen wollen, legen sowohl Kurzfrist- als auch Langfristziele auf Basis von SBTi fest.", "3) Bei den Kurzfrist-Zielen handelt es sich um absolute Ziele zur Reduktion von THG-Emissionen.", "4) Gemäss den SBTi-Anforderungen beginnt der Reduktionspfad ein Jahr nach dem Basisjahr."]);

export const AbsoluteContraction_ShortTerm_coverageScope1andOr2 = singleton("Der Abdeckungsgrad bezieht sich darauf, für welchen Anteil des THG-Footprints Reduktionsziele\n                gesetzt werden müssen. Beim Standard-Ansatz müssen bei den Kurzfrist-Zielen 95% der\n                Scope 1 & 2 Emissionen mit Reduktionszielen abgedeckt sein.");

export const AbsoluteContraction_ShortTerm_coverageScope3 = singleton("Der Abdeckungsgrad bezieht sich darauf, für welchen Anteil des THG-Footprints Reduktionsziele\n                gesetzt werden müssen. Beim Standard-Ansatz müssen bei den Kurzfrist-Zielen 67% der\n                Scope 3 Emissionen mit Reduktionszielen abgedeckt sein.");

export const AbsoluteContraction_ShortTerm_reduction = singleton("Die jährliche geforderte Mindest-Reduktion beträgt unabhängig vom Basisjahr 4.2% für Scope 1 & 2 Emissionen\n                (entspricht dem 1,5-Grad-Ziel) und 2.5% für Scope 3 Emissionen (entspricht dem \"Unter 2-Grad-Ziel\").\n                Für Basisjahre nach 2020 kommt ein Anpassungsfaktor zur Anwendung zur Ermittlung der jährlichen Mindest-Reduktion.\n                Ziel: Unternehmen mit einem Basisjahr nach 2020 müssen gleich viel reduzieren wie Unternehmen mit Basisjahr 2020.");

export const AbsoluteContraction_LongTerm_targetYear = ofArray(["1) Die Festlegung eines SBTi-Langfrist (Netto-Null)-Zieles bedingt das Vorhandensein eines SBTi-Kurzfristzieles (siehe oben).", "2) Bei den Langfrist-Zielen handelt es sich um absolute Ziele zur Reduktion von THG-Emissionen.", "3) Beim Standard-Ansatz müssen Unternehmen für ihre Langfrist-Ziele sowohl für ihre Scope 1 & 2- als auch für ihre Scope 3-Emissionen Ziele setzen.", "4) Langfrist-Ziele müssen bis spätestens 2050 erreicht werden (ausgehend vom gleichen Basisjahr wie für die Kurzfrist-Ziele).", "5) Wenn das Langfristziel erreicht ist, besteht die Verpflichtung, alle noch nicht reduzierten THG-Emissionen durch carbon removals zu neutralisieren."]);

export const AbsoluteContraction_LongTerm_coverage = singleton("Der Abdeckungsgrad bezieht sich darauf, für welchen Anteil des THG-Footprints Reduktionsziele gesetzt werden müssen.\n                Beim Standard-Ansatz müssen bei den Langfrist-Zielen 95% der Scope 1 & 2 Emissionen und 90% der Scope 3 Emissionen mit Reduktionszielen abgedeckt sein.");

export const AbsoluteContraction_LongTerm_reduction = singleton("Beim Standard-Ansatz müssen Unternehmen bis spätestens 2050 eine Mindestreduktion von 90% der Scope 1, 2 und 3 Emissionen\n                erreichen. Wenn das Langfristziel erreicht ist, besteht die Verpflichtung, alle noch nicht reduzierten THG-Emissionen durch\n                carbon removals zu neutralisieren.");

export const KmuApproach_description = ofArray([sbtiDescription, "", "Für KMU besteht dabei ein vereinfachtes Verfahren:", "1) SBTi definiert ein KMU als ein unabhängiges Unternehmen mit weniger als 500 Beschäftigten (= Headcounts), welches keine Tochtergesellschaft ist. SBTi stützt sich bei der Einstufung eines Unternehmens als KMU ausschliesslich auf die oben genannten Kriterien und nur Unternehmen, die diese Kriterien erfüllen, können den vereinfachten Weg für KMU nutzen.", "2) Die SBTi ermöglicht es KMU, die erste Phase (Committment zur Festlegung von \"Science-based targets\") und den Standardprozess der Zielvalidierung auszulassen. KMU können sofort \"Science-based targets\" festlegen (kurzfristige und langfristige Optionen verfügbar), indem sie eine der vordefinierten Zieloptionen auswählen.", "3) Im Gegensatz zu grösseren Unternehmen müssen KMU bei der kurzfristigen Option keine Ziele für ihre Scope 3 Emissionen festlegen; sie müssen sich jedoch verpflichten, ihre Scope 3 Emissionen zu messen und zu reduzieren.", "", "In den Informationsboxen weiter unten finden Sie Detail-Informationen zum SBTi KMU-Ansatz in Bezug auf die folgenden Themen:\n            Scopes 1-3, Basisjahr, Kurzfrist-Zielsetzung und Langfrist-Zielsetzung (Pro Zielsetzung: Beschreibung, min. Abdeckungsgrad, min. Reduktionsziel)."]);

export const KmuApproach_ShortTerm_targetYear = ofArray(["1) Für KMU ist ein festes Kurzfrist-Ziel bis 2030 vorgegeben ausgehend von einem vorher festgelegten Basisjahr.", "2) Bei den Kurzfrist-Zielen handelt es sich um absolute Ziele zur Reduktion von THG-Emissionen.", "3) KMU müssen nur Kurzfrist-Ziele für ihre Scope 1 & 2 Emissionen setzen. KMU müssen sich jedoch verpflichten, ihre Scope 3 Emissionen zu messen und zu reduzieren.", "4) Der Zielpfad orientiert sich an der 1,5°C-Zielsetzung.", "5) Gemäss den SBTi-Anforderungen beginnt der Reduktionspfad ein Jahr nach dem Basisjahr."]);

export const KmuApproach_ShortTerm_coverage = singleton("Der Abdeckungsgrad bezieht sich darauf, für welchen Anteil des THG-Footprints Reduktionsziele gesetzt\n                werden müssen. Beim KMU-Ansatz müssen bei den Kurzfrist-Zielen 95% der Scope 1 & 2 Emissionen\n                mit Reduktionszielen abgedeckt sein.");

export const KmuApproach_ShortTerm_reduction = singleton("Beim KMU-Ansatz müssen Unternehmen bis spätestens 2030 eine Mindestreduktion von 42% der\n                Scope 1 & 2 Emissionen erreichen. Die Mindestreduktion ist abhängig vom Basisjahr:\n                Für jedes Basisjahr ab 2020 beträgt die Mindestreduktion 42% bis 2030. Für Basisjahre nach\n                2020 kommt ein Anpassungsfaktor zur Anwendung zur Ermittlung der jährlichen Mindest-Reduktion.\n                Ziel: Unternehmen mit einem Basisjahr nach 2020 müssen gleich viel reduzieren wie Unternehmen mit Basisjahr 2020.");

export const KmuApproach_LongTerm_targetYear = ofArray(["1) Die Festlegung eines SBTi-Langfrist (Netto-Null)-Zieles bedingt das Vorhandensein eines SBTi-Kurzfristzieles (siehe oben).", "2) Bei den Langfrist-Zielen handelt es sich um absolute Ziele zur Reduktion von THG-Emissionen.", "3) KMU müssen für ihre Langfrist-Ziele sowohl für ihre Scope 1 & 2- als auch für ihre Scope 3 Emissionen Ziele setzen.", "4) Langfrist-Ziele müssen bis spätestens 2050 erreicht werden (ausgehend vom gleichen Basisjahr wie für die Kurzfrist-Ziele).", "5) Wenn das Langfristziel erreicht ist, besteht die Verpflichtung, alle noch nicht reduzierten THG-Emissionen durch carbon removals zu neutralisieren."]);

export const KmuApproach_LongTerm_coverage = singleton("Der Abdeckungsgrad bezieht sich darauf, für welchen Anteil des THG-Footprints Reduktionsziele gesetzt\n                werden müssen. Beim KMU-Ansatz müssen bei den Langfrist-Zielen 95% der Scope 1 & 2 Emissionen und\n                90% der Scope 3 Emissionen mit Reduktionszielen abgedeckt sein.");

export const KmuApproach_LongTerm_reduction = singleton("Beim KMU-Ansatz müssen Unternehmen bis spätestens 2050 eine Mindestreduktion von 90% der\n                Scope 1,2 und 3 Emissionen erreichen. Wenn das Langfristziel erreicht ist, besteht die Verpflichtung,\n                alle noch nicht reduzierten THG-Emissionen durch carbon removals neutralisieren.");

export const PotentialApproach_description = ofArray(["Der Potenzial-Ansatz ist ein \"Bottom-up\"-Ansatz und geht von einer Innenperspektive aus.\n            Die Grundlage bilden dabei identifizierte Potentiale zur Emissionsreduktion in der Wertschöpfungskette\n            eines Unternehmens (Reduktion von Scope 1, 2, 3 Emissionen).", "Die Frage lautet: \"Wieviel KANN ein Unternehmen reduzieren?\" währenddessen bei wissenschaftsbasierten\n            Ansätzen wie dem SBTi-Ansatz die Frage lautet: \"Wieviel MUSS ein Unternehmen reduzieren?\""]);

export const PotentialApproach_ShortTerm_targetYear = ofArray(["1) Beim Potenzial-Ansatz können die Kurzfrist-Ziele einen frei wählbaren Zeitraum umfassen.", "2) Bei den Kurzfrist-Zielen handelt es sich um absolute Ziele zur Reduktion von THG-Emissionen.", "3) Der Reduktionspfad beginnt ein Jahr nach dem Basisjahr."]);

export const PotentialApproach_ShortTerm_coverage = singleton("Der Abdeckungsgrad bezieht sich darauf, für welchen Anteil des THG-Footprints Reduktionsziele gesetzt\n                werden sollen. Beim Potenzial-Ansatz ist der Abdeckungsgrad grundsätzlich frei wählbar.\n                Bei den Scope 3 Emissionen ist der Abdeckungsgrad tendenziell tiefer zu wählen,\n                da die Scope 3 Emissionen schwieriger zu reduzieren sind.");

export const PotentialApproach_ShortTerm_reduction = singleton("Die jährliche Reduktion ist abhängig vom Reduktionspotenzial in der Wertschöpfungskette des Unternehmens\n                und ist dementsprechend frei wählbar (Frage: \"Wieviel KANN ein Unternehmen reduzieren?\").");

export const PotentialApproach_LongTerm_targetYear = ofArray(["1) Die Festlegung eines Langfrist-Zieles bedingt das Vorhandensein eines Kurzfristzieles (siehe oben).", "2) Bei den Langfrist-Zielen handelt es sich um absolute Ziele zur Reduktion von THG-Emissionen.", "3) Beim Potenzial-Ansatz können die Langfrist-Ziele einen frei wählbaren Zeitraum umfassen.", "4) Der Reduktionspfad bei den Langristzielen beginnt nach Beendigung des Kurzfrist-Zieles. Die restlichen Emissionen werden proportional auf den gewählten Zeitraum des Langfrist-Zieles verteilt in Abhängigkeit des \"Gewünschten Abdeckungsgrades\" und des \"Gewünschten Reduktionszieles\" im Langfrist-Ziel (siehe unten)."]);

export const PotentialApproach_LongTerm_coverage = singleton("Der Abdeckungsgrad bezieht sich darauf, für welchen Anteil des THG-Footprints Reduktionsziele gesetzt\n                werden sollen. Beim Potenzial-Ansatz ist der Abdeckungsgrad grundsätzlich frei wählbar.\n                Bei den Scope 3 Emissionen ist der Abdeckungsgrad tendenziell tiefer zu wählen,\n                da die Scope 3 Emissionen schwieriger zu reduzieren sind.");

export const PotentialApproach_LongTerm_reduction = singleton("Beim Potenzial-Ansatz ist das Reduktionsziel grundsätzlich frei wählbar. Bei den Scope 3 Emissionen ist das\n                Reduktionsziel tendenziell tiefer zu wählen, da die Scope 3 Emissionen schwieriger zu reduzieren sind.");

export const feasibilityCoarse = ofArray(["1) *Festlegen der groben Machbarkeit*: In der Spalte \"MK\" machen Sie eine erste unternehmensspezifische Einschätzung in Bezug auf die Umsetzbarkeit von Reduktonsmassnahmen pro Emissionsquelle (MK 1: Gut / MK2: Mittel / MK 3: Schlecht)", "2) *Button \"Liste sortieren\" auswählen*: Die Emissionsquellen mit der \"besten\" Machbarkeit (MK=1) erschienen absteigend sortiert nach der Grösse zuobert in der Tabelle und im Diagramm."]);

export const feasibilitySpecific = ofArray(["Entwicklung eines  THG-Reduktionsszenario für Ihr Unternehmen:", "1) *Setzen eines Scope-spezifischen Reduktionszieles*: Die Reduktionsmatrix ist default-mässig auf 0 gestellt. Im Inputfeld \"Kurzfristige Reduktion p.a. in %\" können Sie z.B. eine generelle Reduktion von 5% p.a. für alle Emissionsquellen des entsprechenden Scopes eingeben.", "2) *Setzen eines Emissions-spezifischen Reduktionszieles*: Direkt in der Tabelle können Sie spezifische Reduktionsziele setzen, indem Sie pro Emissionsquelle eine jahrespezifische Reduktion eingeben. *In jedem Inputfeld in der Tabelle können Sie einen entsprechenden Kommentar eingeben.*"]);

export const upload = ofArray(["1) *Mit Link \"Vorlagedatei\" Vorlage-Datei herunterladen:* Footprint muss genau dieser Excel-Vorlage entsprechen für einen fehlerfreien Upload.", "2) *Button \"Datei auswählen\":* Datei uploaden.", "3) *Darstellung Upload in Tabelle:* Die Emissionsquellen werden automatisch sortiert nach der Grösse. Die grössten Emissionsquellen erscheinen zuoberst in der Tabelle.", "4) *Darstellung Upload im Diagramm:* Es werden nur diejenigen Emissionsquellen angezeigt, welche kumulativ die geforderte Abdeckung ergeben (Scope 1 & 2: 95% / Scope 3: 66%).", "5) *Manuelle Eingabe und / oder Veränderung von Emissionsquellen:* Einzelne Emissionsquellen (Bezeichnung) und die entsprechenden Emissionen (Grösse der Emission) können direkt ins Tool geschrieben werden.\n        Auch später sind jederzeit manuelle Anpassungen einzelner Emissionsquellen möglich"]);

export const restEmissions = ofArray(["1) *Was sind Restemissionen?* Als \"Restemissionen\" bezeichen wir diejenigen Emissionsquellen, welche kumuliert die restlichen 10 - 20% der Gesamtemissionen bilden. Eine 80/20-Analyse des THG-Footprints zeigt dabei immer ein ähnliches Bild: Eine Vielzahl kleiner Emissionsquellen ergeben kumuliert 10-20% der Gesamtemissionen.", "2) *Umgang mit \"Restemissionen\" im Tool:* Restemissionen bilden nicht den grossen Hebel in der THG-Reduktion. Darum empfehlen wir a) eine 80/20-Analyse des THG Footprints zu erstellen, b) mit den kleinen Emissionsquellen (kumuliert 10 - 20% der Gesamtemissionen) eine eigene Kategorie \"Restemissionen\" zu bilden und in den THG-Footprint zu inkludieren.", "3) *Ergebnis nach Upload THG-Footprint:* In der Tabelle und im Diagramm erscheint die Kategorie \"Restemissionen\" immer zuunterst - unabhängig von der Höhe der kumulierten Gesamtemissionen. Die Machbarkeit ist defaultmässig auf \"3\" gestellt (MK = 3: Die Umsetzbarkeit von Reduktionsmassnahmen wird als schlecht beurteilt)."]);

