import { Record, Union } from "../fable_modules/fable-library-js.4.16.0/Types.js";
import { enum_type, bool_type, option_type, tuple_type, int32_type, record_type, class_type, list_type, string_type, union_type } from "../fable_modules/fable-library-js.4.16.0/Reflection.js";
import { empty } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { SavedState, SavedStateInfo, SavedStateInfo_$reflection } from "../../Shared/Database/Database.fs.js";
import { UserParameters_$reflection } from "../../Shared/BaseTypes/Targets.fs.js";
import { UserData_$reflection } from "../../Shared/Database/UserData.fs.js";
import { Scope_$reflection } from "../../Shared/BaseTypes/Scope.fs.js";
import { EmissionSourceId_$reflection } from "../../Shared/BaseTypes/Emissions.fs.js";
import { now } from "../fable_modules/fable-library-js.4.16.0/Date.js";

export class StatusArea_Level extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Info", "Warn", "Error"];
    }
}

export function StatusArea_Level_$reflection() {
    return union_type("AccelerateGreen.WebUI.StatusArea.Level", [], StatusArea_Level, () => [[], [], []]);
}

export class StatusArea_Message extends Record {
    constructor(Level, Text$, Duration) {
        super();
        this.Level = Level;
        this.Text = Text$;
        this.Duration = Duration;
    }
}

export function StatusArea_Message_$reflection() {
    return record_type("AccelerateGreen.WebUI.StatusArea.Message", [], StatusArea_Message, () => [["Level", StatusArea_Level_$reflection()], ["Text", list_type(string_type)], ["Duration", class_type("System.TimeSpan")]]);
}

export class StatusArea_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Show", "Hide"];
    }
}

export function StatusArea_Msg_$reflection() {
    return union_type("AccelerateGreen.WebUI.StatusArea.Msg", [], StatusArea_Msg, () => [[["Item", StatusArea_Message_$reflection()]], [["Item", int32_type]]]);
}

export class StatusArea_State extends Record {
    constructor(Messages) {
        super();
        this.Messages = Messages;
    }
}

export function StatusArea_State_$reflection() {
    return record_type("AccelerateGreen.WebUI.StatusArea.State", [], StatusArea_State, () => [["Messages", list_type(tuple_type(int32_type, StatusArea_Message_$reflection()))]]);
}

export const StatusArea_initialState = new StatusArea_State(empty());

export class Storage extends Record {
    constructor(Name, LastChange, StateInfos, Selection$) {
        super();
        this.Name = Name;
        this.LastChange = LastChange;
        this.StateInfos = StateInfos;
        this.Selection = Selection$;
    }
}

export function Storage_$reflection() {
    return record_type("AccelerateGreen.WebUI.Storage", [], Storage, () => [["Name", string_type], ["LastChange", option_type(class_type("System.DateTime"))], ["StateInfos", list_type(SavedStateInfo_$reflection())], ["Selection", option_type(string_type)]]);
}

export class Dimensions extends Record {
    constructor(Top, Left, Width, Height) {
        super();
        this.Top = (Top | 0);
        this.Left = (Left | 0);
        this.Width = (Width | 0);
        this.Height = (Height | 0);
    }
}

export function Dimensions_$reflection() {
    return record_type("AccelerateGreen.WebUI.Dimensions", [], Dimensions, () => [["Top", int32_type], ["Left", int32_type], ["Width", int32_type], ["Height", int32_type]]);
}

export class State extends Record {
    constructor(Storage, IsServerReachable, TargetType, ScopeSelection, UserParameters, UserData, ShowAllScopes, FocusedEmissionSource, StatusArea) {
        super();
        this.Storage = Storage;
        this.IsServerReachable = IsServerReachable;
        this.TargetType = (TargetType | 0);
        this.ScopeSelection = (ScopeSelection | 0);
        this.UserParameters = UserParameters;
        this.UserData = UserData;
        this.ShowAllScopes = ShowAllScopes;
        this.FocusedEmissionSource = FocusedEmissionSource;
        this.StatusArea = StatusArea;
    }
}

export function State_$reflection() {
    return record_type("AccelerateGreen.WebUI.State", [], State, () => [["Storage", Storage_$reflection()], ["IsServerReachable", bool_type], ["TargetType", enum_type("AccelerateGreen.NetZeroScenario.DbModel.TargetType", int32_type, [["PotentialApproach", 0], ["AbsoluteContraction", 1], ["KmuApproach", 2], ["PhysicalIntensity", 3]])], ["ScopeSelection", enum_type("AccelerateGreen.NetZeroScenario.DbModel.ScopeSelection", int32_type, [["S1", 0], ["S2", 1], ["S12", 2], ["S3", 3]])], ["UserParameters", UserParameters_$reflection()], ["UserData", UserData_$reflection()], ["ShowAllScopes", bool_type], ["FocusedEmissionSource", option_type(tuple_type(Scope_$reflection(), EmissionSourceId_$reflection(), Dimensions_$reflection()))], ["StatusArea", StatusArea_State_$reflection()]]);
}

export function StateModule_extractSavedState(state, lastId) {
    return new SavedState(new SavedStateInfo(now(), state.Storage.Name), state.TargetType, state.ScopeSelection, state.UserParameters, state.UserData, lastId);
}

export function StateModule_insertSavedState(saved, state) {
    let bind$0040;
    return [new State((bind$0040 = state.Storage, new Storage(saved.Info.Name, saved.Info.Creation, bind$0040.StateInfos, bind$0040.Selection)), state.IsServerReachable, saved.TargetType, saved.ScopeSelection, saved.UserParameters, saved.UserData, state.ShowAllScopes, state.FocusedEmissionSource, state.StatusArea), saved.LastId];
}

