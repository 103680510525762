import { filter, empty, fold, findIndex, updateAt, singleton, append, cons, map2, toArray, pairwise, map, tail, isEmpty } from "./fable_modules/fable-library-js.4.16.0/List.js";
import { compare, equals } from "./fable_modules/fable-library-js.4.16.0/Util.js";
import { isNullOrEmpty, format } from "./fable_modules/fable-library-js.4.16.0/String.js";
import { containsKey, ofList, remove, tryFind } from "./fable_modules/fable-library-js.4.16.0/Map.js";
import { value } from "./fable_modules/fable-library-js.4.16.0/Option.js";

/**
 * Build an array of booleans indicating where the 'list' items don't stay constant (relative to their neighbors).
 * The first and last items are always considered non-constant and hence are true.
 * Example:
 * getSupportPoints [1;1;1;2;3;4;5;5;5] = [|true; false; true; true; true; true; true; false; true|]
 */
export function Helpers_getSupportPoints(list) {
    if (!isEmpty(list)) {
        if (!isEmpty(tail(list))) {
            if (isEmpty(tail(tail(list)))) {
                return [true, true];
            }
            else {
                const changes = map((tupledArg) => !equals(tupledArg[0], tupledArg[1]), pairwise(list));
                return toArray(map2((e, e_1) => (e || e_1), cons(true, changes), append(changes, singleton(true))));
            }
        }
        else {
            return [true];
        }
    }
    else {
        return new Array(0);
    }
}

/**
 * Render float with one digit after decimal separator
 * Example: render 2.46 = "2.5"
 */
export function Float_render(v) {
    return format("{0:#.0}", v);
}

/**
 * Render float as integer.
 * Example: render 2.46 = "2"
 */
export function Float_renderAsInt(v) {
    return format("{0:#}", v);
}

export function List_updateWhere(pred, newValue, list) {
    return updateAt(findIndex(pred, list), newValue, list);
}

export function List_addOrUpdate(key, newItems, list) {
    const patternInput = fold((tupledArg, item_1) => {
        const acc = tupledArg[0];
        const remaining = tupledArg[1];
        const key_1 = key(item_1);
        const matchValue = tryFind(key_1, remaining);
        if (matchValue == null) {
            return [cons(item_1, acc), remaining];
        }
        else {
            return [cons(value(matchValue), acc), remove(key_1, remaining)];
        }
    }, [empty(), ofList(map((item) => [key(item), item], newItems), {
        Compare: compare,
    })], list);
    return append(patternInput[0], filter((x_2) => containsKey(key(x_2), patternInput[1]), newItems));
}

export function Option_fromString(s) {
    if (isNullOrEmpty(s)) {
        return void 0;
    }
    else {
        return s;
    }
}

