import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { join } from "../fable_modules/fable-library-js.4.16.0/String.js";
import { singleton, ofArray, append } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { toArray } from "../fable_modules/fable-library-js.4.16.0/Option.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { Common_lazyView2With } from "../fable_modules/Fable.Elmish.React.4.0.0/common.fs.js";

/**
 * Build a panel.
 */
export function renderPanel(className, title, content) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", [className, "panel"])], (elems_1 = append(ofArray(toArray(title)), singleton(createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(content)),
    }))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function intToEnum() {
    return (value) => value;
}

export function handleUpload(onComplete, fileEvent) {
    const files = fileEvent.target.files;
    if (files.length === 1) {
        const reader = new FileReader();
        reader.onload = ((_arg) => {
            const bytes = reader.result;
            onComplete([(files[0]).name, bytes]);
        });
        reader.readAsArrayBuffer(files[0]);
    }
}

export function areEqualBy(getKey, x, y) {
    return equals(getKey(x), getKey(y));
}

export function renderLazily(render, getSubset) {
    return (state) => ((dispatch) => Common_lazyView2With((x, y) => areEqualBy(getSubset, x, y), render, state, dispatch));
}

