import { Record, Union } from "../../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { record_type, union_type } from "../../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";

export class Scope extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Scope1", "Scope2", "Scope3"];
    }
}

export function Scope_$reflection() {
    return union_type("AccelerateGreen.NetZeroScenario.DbModel.Scope", [], Scope, () => [[], [], []]);
}

export class ScopeValues$1 extends Record {
    constructor(Scope1, Scope2, Scope3) {
        super();
        this.Scope1 = Scope1;
        this.Scope2 = Scope2;
        this.Scope3 = Scope3;
    }
}

export function ScopeValues$1_$reflection(gen0) {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.ScopeValues`1", [gen0], ScopeValues$1, () => [["Scope1", gen0], ["Scope2", gen0], ["Scope3", gen0]]);
}

export class ScopeGroupValues$1 extends Record {
    constructor(Scope1and2, Scope3) {
        super();
        this.Scope1and2 = Scope1and2;
        this.Scope3 = Scope3;
    }
}

export function ScopeGroupValues$1_$reflection(gen0) {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.ScopeGroupValues`1", [gen0], ScopeGroupValues$1, () => [["Scope1and2", gen0], ["Scope3", gen0]]);
}

export class ScopeSelectionValues$1 extends Record {
    constructor(S1, S2, S3, S12) {
        super();
        this.S1 = S1;
        this.S2 = S2;
        this.S3 = S3;
        this.S12 = S12;
    }
}

export function ScopeSelectionValues$1_$reflection(gen0) {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.ScopeSelectionValues`1", [gen0], ScopeSelectionValues$1, () => [["S1", gen0], ["S2", gen0], ["S3", gen0], ["S12", gen0]]);
}

