import { Record, Union } from "../../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { list_type, class_type, float64_type, enum_type, string_type, record_type, union_type, int32_type } from "../../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";
import { Year_$reflection } from "./Year.fs.js";
import { exists, ofArray } from "../../Client/fable_modules/fable-library-js.4.16.0/List.js";
import { compare } from "../../Client/fable_modules/fable-library-js.4.16.0/String.js";
import { compareArrays, comparePrimitives } from "../../Client/fable_modules/fable-library-js.4.16.0/Util.js";

export class EmissionSourceId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["EmissionSourceId"];
    }
}

export function EmissionSourceId_$reflection() {
    return union_type("AccelerateGreen.NetZeroScenario.DbModel.EmissionSourceId", [], EmissionSourceId, () => [[["Item", int32_type]]]);
}

let EmissionSourceIdModule_nextId = 1;

export function EmissionSourceIdModule_create() {
    const id = EmissionSourceIdModule_nextId | 0;
    EmissionSourceIdModule_nextId = ((EmissionSourceIdModule_nextId + 1) | 0);
    return new EmissionSourceId(id);
}

export function EmissionSourceIdModule_Last_set(_arg) {
    EmissionSourceIdModule_nextId = ((_arg.fields[0] + 1) | 0);
}

export function EmissionSourceIdModule_Last_get() {
    return new EmissionSourceId(EmissionSourceIdModule_nextId - 1);
}

export function EmissionSourceIdModule_Serialization_toInt(_arg) {
    return _arg.fields[0];
}

export function EmissionSourceIdModule_Serialization_fromInt(id) {
    return new EmissionSourceId(id);
}

export class YearValue$1 extends Record {
    constructor(Year, Value) {
        super();
        this.Year = Year;
        this.Value = Value;
    }
}

export function YearValue$1_$reflection(gen0) {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.YearValue`1", [gen0], YearValue$1, () => [["Year", Year_$reflection()], ["Value", gen0]]);
}

export class EmissionSource extends Record {
    constructor(Id, Name, Feasibility, FootPrint, Overrides, OverrideNotes) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Feasibility = (Feasibility | 0);
        this.FootPrint = FootPrint;
        this.Overrides = Overrides;
        this.OverrideNotes = OverrideNotes;
    }
}

export function EmissionSource_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.EmissionSource", [], EmissionSource, () => [["Id", EmissionSourceId_$reflection()], ["Name", string_type], ["Feasibility", enum_type("AccelerateGreen.NetZeroScenario.DbModel.Difficulty", int32_type, [["Easy", 1], ["Moderate", 2], ["Hard", 3]])], ["FootPrint", float64_type], ["Overrides", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [Year_$reflection(), float64_type])], ["OverrideNotes", string_type]]);
}

export const EmissionSourceModule_restName = "Restemissionen";

const EmissionSourceModule_allRestNames = ofArray([EmissionSourceModule_restName, "Rest-Emissionen", "Rest"]);

/**
 * Compare two emission sources, first by feasibility (easy < moderate < hard),
 * and then by footprint (bigger < smaller!). Sources matching one of the 'restNames'
 * are always last.
 */
export function EmissionSourceModule_compare(e1, e2) {
    const isRest = (source) => {
        const sourceName = source.Name.trim();
        return exists((restName) => (compare(restName, sourceName, 5) === 0), EmissionSourceModule_allRestNames);
    };
    const feasibility = comparePrimitives(e1.Feasibility, e2.Feasibility) | 0;
    if (feasibility === 0) {
        return compareArrays([isRest(e1), -e1.FootPrint], [isRest(e2), -e2.FootPrint]) | 0;
    }
    else {
        return feasibility | 0;
    }
}

export class TermValuesGeneric$2 extends Record {
    constructor(Short, Long) {
        super();
        this.Short = Short;
        this.Long = Long;
    }
}

export function TermValuesGeneric$2_$reflection(gen0, gen1) {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.TermValuesGeneric`2", [gen0, gen1], TermValuesGeneric$2, () => [["Short", gen0], ["Long", gen1]]);
}

export class ScopeEmissions extends Record {
    constructor(BaseYearEmissions, Sources, ReductionPlan) {
        super();
        this.BaseYearEmissions = BaseYearEmissions;
        this.Sources = Sources;
        this.ReductionPlan = ReductionPlan;
    }
}

export function ScopeEmissions_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.ScopeEmissions", [], ScopeEmissions, () => [["BaseYearEmissions", float64_type], ["Sources", list_type(EmissionSource_$reflection())], ["ReductionPlan", TermValuesGeneric$2_$reflection(float64_type, float64_type)]]);
}

