import { Record } from "../../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { YearModule_next, YearModule_prev, YearModule_step, YearModule_durationExcl, Year_$reflection } from "./Year.fs.js";
import { record_type } from "../../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";
import { max, compare, min } from "../../Client/fable_modules/fable-library-js.4.16.0/Util.js";

export class TimeLine extends Record {
    constructor(Short, Long, Rest) {
        super();
        this.Short = Short;
        this.Long = Long;
        this.Rest = Rest;
    }
}

export function TimeLine_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.TimeLine", [], TimeLine, () => [["Short", Year_$reflection()], ["Long", Year_$reflection()], ["Rest", Year_$reflection()]]);
}

export function TimeLine__get_ShortDuration(x) {
    return YearModule_durationExcl(x.Short, x.Long);
}

export function TimeLine__get_LongDuration(x) {
    return YearModule_durationExcl(x.Long, x.Rest);
}

export function TimeLine__get_Duration(x) {
    return YearModule_durationExcl(x.Short, x.Rest);
}

export function TimeLineModule_offsetBy(a, b, start) {
    return [YearModule_step(a, start), YearModule_step(b, start)];
}

export function TimeLineModule_forceIntoRange(start, stop, cur) {
    return min(compare, max(compare, cur, start), stop);
}

export const TimeLineModule_shortDurationRange = [5, 10];

export function TimeLineModule_adjustLong(newShort, curLong) {
    const curShortEnd = YearModule_prev(curLong);
    const range = TimeLineModule_offsetBy(TimeLineModule_shortDurationRange[0], TimeLineModule_shortDurationRange[1], newShort);
    return YearModule_next(TimeLineModule_forceIntoRange(range[0], range[1], curShortEnd));
}

