import { Scope_toInt, ScopeSelection_getList, ScopeSelection_getAll, ScopeValues_set, ScopeValues_get } from "../../Shared/BaseTypes/ScopeHelpers.fs.js";
import { UserData as UserData_1 } from "../../Shared/Database/UserData.fs.js";
import { Dimensions, State } from "../Base/State.fs.js";
import { defaultArg, map } from "../fable_modules/fable-library-js.4.16.0/Option.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { concat, cons, sumBy, empty, map as map_1, ofArray, sortWith, singleton, append, find, filter } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { createObj, compare, equals } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { TermValuesGeneric$2, EmissionSourceModule_compare, EmissionSource, ScopeEmissions } from "../../Shared/BaseTypes/Emissions.fs.js";
import { isEmpty, tryFind, keys, add } from "../fable_modules/fable-library-js.4.16.0/Map.js";
import { Float_renderAsInt, List_updateWhere } from "../UIHelpers.fs.js";
import { newEmissionSource } from "../Base/InitialState.fs.js";
import { EnumDropDown_render, NumberInput_allowOnlyIntKeys, Tooltip_render, NumberInput_renderFloat } from "../Controls/Controls.fs.js";
import { ImportEmissionSourcesFile, Msg, OverrideChange, SourceDataChange, ShortOrLong, PlanValue } from "../Base/Msg.fs.js";
import { upload, difficultyNames, feasibilityCoarse, restEmissions, feasibilitySpecific } from "./Texts.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { contains, ofSeq, unionMany } from "../fable_modules/fable-library-js.4.16.0/Set.js";
import { take, empty as empty_1, singleton as singleton_1, append as append_1, delay, map as map_2, toList } from "../fable_modules/fable-library-js.4.16.0/Seq.js";
import { isNullOrWhiteSpace, printf, toText, join } from "../fable_modules/fable-library-js.4.16.0/String.js";
import { YearModule_countFrom, YearModule_next, YearModule_rangeExclToSeq, Year } from "../../Shared/BaseTypes/Year.fs.js";
import { TimeLine__get_Duration, TimeLine__get_LongDuration, TimeLine__get_ShortDuration } from "../../Shared/BaseTypes/TimeLine.fs.js";
import { fixAndParseFloat } from "../Controls/NumberInput.fs.js";
import { handleUpload, renderPanel, intToEnum } from "../Controls/Helpers.fs.js";
import { TargetTypeValues_get } from "../../Shared/BaseTypes/Targets.fs.js";

export function getEmissions(state, scope) {
    return ScopeValues_get(scope, state.UserData.Emissions);
}

export function setEmissions(state, scope, e) {
    let bind$0040;
    return new State(state.Storage, state.IsServerReachable, state.TargetType, state.ScopeSelection, state.UserParameters, (bind$0040 = state.UserData, new UserData_1(bind$0040.TimeLine, bind$0040.BaseYear, ScopeValues_set(scope, e, state.UserData.Emissions))), state.ShowAllScopes, state.FocusedEmissionSource, state.StatusArea);
}

export function update(scope, newValue, state) {
    let bind$0040;
    let matchResult, dimensions, id;
    if (newValue.tag === 1) {
        if (newValue.fields[1].tag === 5) {
            matchResult = 0;
            dimensions = newValue.fields[1].fields[0];
            id = newValue.fields[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return [new State(state.Storage, state.IsServerReachable, state.TargetType, state.ScopeSelection, state.UserParameters, state.UserData, state.ShowAllScopes, map((d) => [scope, id, d], dimensions), state.StatusArea), Cmd_none()];
        default: {
            const emissions = getEmissions(state, scope);
            const sources = emissions.Sources;
            let patternInput_1;
            switch (newValue.tag) {
                case 1: {
                    if (newValue.fields[1].tag === 6) {
                        patternInput_1 = [new ScopeEmissions(emissions.BaseYearEmissions, filter((e) => !equals(e.Id, newValue.fields[0]), sources), emissions.ReductionPlan), void 0];
                    }
                    else {
                        const source = find((e_1) => equals(e_1.Id, newValue.fields[0]), sources);
                        let patternInput;
                        switch (newValue.fields[1].tag) {
                            case 1: {
                                patternInput = [new EmissionSource(source.Id, source.Name, newValue.fields[1].fields[0], source.FootPrint, source.Overrides, source.OverrideNotes), void 0];
                                break;
                            }
                            case 2: {
                                patternInput = [new EmissionSource(source.Id, source.Name, source.Feasibility, newValue.fields[1].fields[0], source.Overrides, source.OverrideNotes), void 0];
                                break;
                            }
                            case 3: {
                                const info = newValue.fields[1].fields[0];
                                patternInput = [new EmissionSource(source.Id, source.Name, source.Feasibility, source.FootPrint, add(info.Year, info.NewValue, source.Overrides), source.OverrideNotes), state.FocusedEmissionSource];
                                break;
                            }
                            case 4: {
                                patternInput = [new EmissionSource(source.Id, source.Name, source.Feasibility, source.FootPrint, source.Overrides, newValue.fields[1].fields[0]), state.FocusedEmissionSource];
                                break;
                            }
                            case 5:
                            case 6: {
                                throw new Error("This must never happen.");
                                break;
                            }
                            default:
                                patternInput = [new EmissionSource(source.Id, newValue.fields[1].fields[0], source.Feasibility, source.FootPrint, source.Overrides, source.OverrideNotes), void 0];
                        }
                        patternInput_1 = [new ScopeEmissions(emissions.BaseYearEmissions, List_updateWhere((e_2) => equals(e_2.Id, newValue.fields[0]), patternInput[0], sources), emissions.ReductionPlan), patternInput[1]];
                    }
                    break;
                }
                case 2: {
                    patternInput_1 = [new ScopeEmissions(emissions.BaseYearEmissions, append(sources, singleton(newEmissionSource())), emissions.ReductionPlan), void 0];
                    break;
                }
                case 3: {
                    patternInput_1 = [new ScopeEmissions(emissions.BaseYearEmissions, sortWith(EmissionSourceModule_compare, sources), emissions.ReductionPlan), void 0];
                    break;
                }
                default: {
                    const red = emissions.ReductionPlan;
                    patternInput_1 = [new ScopeEmissions(emissions.BaseYearEmissions, emissions.Sources, (newValue.fields[0].tag === 1) ? (new TermValuesGeneric$2(red.Short, newValue.fields[1])) : (new TermValuesGeneric$2(newValue.fields[1], red.Long))), void 0];
                }
            }
            return [(bind$0040 = setEmissions(state, scope, patternInput_1[0]), new State(bind$0040.Storage, bind$0040.IsServerReachable, bind$0040.TargetType, bind$0040.ScopeSelection, bind$0040.UserParameters, bind$0040.UserData, bind$0040.ShowAllScopes, patternInput_1[1], bind$0040.StatusArea)), Cmd_none()];
        }
    }
}

function renderDefaultReductionTextBox(name, term, value, dispatch) {
    let children;
    return ofArray([name + " Reduktion p.a.", (children = ofArray([NumberInput_renderFloat(void 0, 2, 0, value, (arg_1) => {
        dispatch(new PlanValue(0, [term, arg_1]));
    }), " %", Tooltip_render(feasibilitySpecific)]), createElement("span", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))]);
}

function renderYearHeaders(emissions, start, endExcl) {
    const overriddenYears = unionMany(map_1((src) => ofSeq(keys(src.Overrides), {
        Compare: compare,
    }), emissions.Sources), {
        Compare: compare,
    });
    return toList(map_2((_arg) => {
        let elems;
        const yearNo = _arg.fields[0] | 0;
        return createElement("td", createObj(ofArray([["className", "year"], (elems = [createElement("span", {
            className: join(" ", contains(new Year(yearNo), overriddenYears) ? singleton("overridden") : empty()),
            children: yearNo,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }, YearModule_rangeExclToSeq(start, endExcl)));
}

const sourceDescriptionColumns = 3;

function renderHeaders(scopeNo, tl, emissions, dispatch) {
    return toList(delay(() => {
        let elems_4;
        const sum = ~~sumBy((_arg) => _arg.FootPrint, emissions.Sources, {
            GetZero: () => 0,
            Add: (x, y) => (x + y),
        }) | 0;
        return append_1(singleton_1(createElement("tr", createObj(ofArray([["className", "scope-headers"], (elems_4 = toList(delay(() => {
            let elems_1, elems;
            return append_1(singleton_1(createElement("td", createObj(ofArray([["colSpan", sourceDescriptionColumns], (elems_1 = [toText(printf("Scope %d (%d t)"))(scopeNo)(sum), createElement("div", createObj(ofArray([["className", "list-buttons"], (elems = [createElement("button", {
                children: "Neue Emissionsquelle",
                onClick: (_arg_1) => {
                    dispatch(new PlanValue(2, []));
                },
            }), createElement("button", {
                children: "Liste sortieren",
                onClick: (_arg_2) => {
                    dispatch(new PlanValue(3, []));
                },
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => append_1(singleton_1(createElement("td", {
                className: "gap-col",
            })), delay(() => append_1(singleton_1(createElement("td", {})), delay(() => {
                let elems_2;
                return append_1(singleton_1(createElement("td", createObj(ofArray([["className", "default-reduction"], ["colSpan", TimeLine__get_ShortDuration(tl) - 1], (elems_2 = renderDefaultReductionTextBox("Kurzfristige", new ShortOrLong(0, []), emissions.ReductionPlan.Short, dispatch), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => {
                    let elems_3;
                    return (TimeLine__get_LongDuration(tl) > 0) ? singleton_1(createElement("td", createObj(ofArray([["className", "default-reduction"], ["colSpan", TimeLine__get_LongDuration(tl)], (elems_3 = renderDefaultReductionTextBox("Langfristige", new ShortOrLong(1, []), emissions.ReductionPlan.Long, dispatch), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))) : empty_1();
                }));
            }))))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
            let elems_7;
            return singleton_1(createElement("tr", createObj(ofArray([["className", "column-headers"], (elems_7 = toList(delay(() => append_1(singleton_1(createElement("td", {
                className: "name",
                children: "Emissionsquelle",
            })), delay(() => append_1(singleton_1(createElement("td", {
                className: "footprint",
                children: "Foot- print",
            })), delay(() => {
                let elems_5;
                return append_1(singleton_1(createElement("td", createObj(ofArray([["className", "delete"], (elems_5 = [Tooltip_render(restEmissions)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => append_1(singleton_1(createElement("td", {
                    className: "gap-col",
                })), delay(() => {
                    let elems_6;
                    return append_1(singleton_1(createElement("td", createObj(ofArray([["className", "feasibility"], (elems_6 = ["MK", Tooltip_render(feasibilityCoarse)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))), delay(() => append_1(renderYearHeaders(emissions, YearModule_next(tl.Short), tl.Long), delay(() => ((TimeLine__get_LongDuration(tl) > 0) ? renderYearHeaders(emissions, tl.Long, tl.Rest) : empty_1())))));
                }))));
            })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))));
        }));
    }));
}

function renderCellInput(value, focusDispatch, changeDispatch) {
    let value_9;
    return createElement("input", createObj(ofArray([["onKeyPress", (ev) => {
        NumberInput_allowOnlyIntKeys(ev);
    }], ["onChange", (ev_1) => {
        changeDispatch(fixAndParseFloat(2, 0, ev_1.target.value));
    }], ["onFocus", (ev_2) => {
        let Left;
        const el = ev_2.target.parentElement;
        focusDispatch((Left = (~~el.offsetLeft | 0), new Dimensions(~~el.offsetTop, Left, ~~el.offsetWidth, ~~el.offsetHeight)));
    }], ["onBlur", (ev_3) => {
        let el_1;
        const matchValue = ev_3.relatedTarget;
        let matchResult, el_2;
        if (matchValue instanceof HTMLTextAreaElement) {
            if ((el_1 = matchValue, el_1.parentElement.className === "override-notes")) {
                matchResult = 0;
                el_2 = matchValue;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0: {
                break;
            }
            case 1: {
                focusDispatch(void 0);
                break;
            }
        }
    }], (value_9 = Float_renderAsInt(value), ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_9)) {
            e.value = value_9;
        }
    }])])));
}

function renderTerm(source, defaultReduction, startYear, duration, dispatch) {
    return map_2((yr) => {
        let elems;
        const tupledArg = [yr, tryFind(yr, source.Overrides)];
        const overrideValue = tupledArg[1];
        return createElement("td", createObj(ofArray([["className", (overrideValue != null) ? "overridden" : "default"], (elems = toList(delay(() => append_1(singleton_1(renderCellInput(defaultArg(overrideValue, defaultReduction), (arg) => {
            dispatch(new SourceDataChange(5, [arg]));
        }, (arg_1) => {
            dispatch(new SourceDataChange(3, [new OverrideChange(tupledArg[0], arg_1)]));
        })), delay(() => ((overrideValue != null) ? singleton_1(createElement("div", {})) : empty_1()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }, take(duration, YearModule_countFrom(startYear)));
}

function renderSource(tl, defaultReductions, source, dispatch) {
    let elems_5;
    return createElement("tr", createObj(ofArray([["className", join(" ", cons("source", !isNullOrWhiteSpace(source.OverrideNotes) ? singleton("has-notes") : empty()))], (elems_5 = toList(delay(() => {
        let elems, value_4;
        return append_1(singleton_1(createElement("td", createObj(ofArray([["className", "name"], (elems = [createElement("input", createObj(ofArray([["onChange", (ev) => {
            dispatch(new SourceDataChange(0, [ev.target.value]));
        }], (value_4 = source.Name, ["ref", (e) => {
            if (!(e == null) && !equals(e.value, value_4)) {
                e.value = value_4;
            }
        }])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_1;
            return append_1(singleton_1(createElement("td", createObj(ofArray([["className", "footprint"], (elems_1 = [NumberInput_renderFloat("footprint", 6, 0, source.FootPrint, (arg_2) => {
                dispatch(new SourceDataChange(2, [arg_2]));
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let elems_3, elems_2;
                return append_1(singleton_1(createElement("td", createObj(ofArray([["className", "delete"], (elems_3 = [createElement("button", createObj(ofArray([["title", "Emissionsquelle löschen"], ["onClick", (_arg) => {
                    dispatch(new SourceDataChange(6, []));
                }], (elems_2 = [createElement("i", {
                    className: join(" ", ["fa", "fa-trash"]),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => append_1(singleton_1(createElement("td", {
                    className: "gap-col",
                })), delay(() => {
                    let elems_4;
                    return append_1(singleton_1(createElement("td", createObj(ofArray([["className", "feasibility"], (elems_4 = [EnumDropDown_render(source.Feasibility, difficultyNames, (arg_4) => {
                        dispatch(new SourceDataChange(1, [intToEnum()(arg_4)]));
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => append_1(renderTerm(source, defaultReductions.Short, YearModule_next(tl.Short), TimeLine__get_ShortDuration(tl) - 1, dispatch), delay(() => ((TimeLine__get_LongDuration(tl) > 0) ? renderTerm(source, defaultReductions.Long, tl.Long, TimeLine__get_LongDuration(tl), dispatch) : empty_1())))));
                }))));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function renderSources(tl, emissions, dispatch) {
    return map_1((src) => renderSource(tl, emissions.ReductionPlan, src, (x) => {
        dispatch(new PlanValue(1, [src.Id, x]));
    }), emissions.Sources);
}

export function renderGapRow(tl) {
    let elems;
    return createElement("tr", createObj(ofArray([["className", "gap"], (elems = [createElement("td", {
        colSpan: (sourceDescriptionColumns + 1) + TimeLine__get_Duration(tl),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function renderScope(scopeNo, tl, emissions, dispatch) {
    return toList(delay(() => append_1(renderHeaders(scopeNo, tl, emissions, dispatch), delay(() => append_1(renderSources(tl, emissions, dispatch), delay(() => singleton_1(renderGapRow(tl))))))));
}

export function render(state, dispatch) {
    let children, elems_6, elems_10, elems_7, elems_9;
    const scopeEmissionList = state.ShowAllScopes ? ScopeSelection_getAll(state.UserData.Emissions) : ScopeSelection_getList(state.ScopeSelection, state.UserData.Emissions);
    const tl = TargetTypeValues_get(state.TargetType, state.UserData.TimeLine);
    return renderPanel("plan", (children = ofArray(["PLAN - ", createElement("span", {
        children: ["Treibhausgas-Reduktionsszenarien"],
    })]), createElement("h2", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), [createElement("div", createObj(ofArray([["className", "table-container"], (elems_6 = toList(delay(() => {
        let children_4, children_2;
        return append_1(singleton_1((children_4 = singleton((children_2 = toList(delay(() => {
            let elems;
            return append_1(singleton_1(createElement("tr", createObj(ofArray([["className", "columns"], (elems = [createElement("td", {
                className: "name",
            }), createElement("td", {
                className: "footprint",
            }), createElement("td", {
                className: "delete",
            }), createElement("td", {
                className: "gap-col",
            }), createElement("td", {
                className: "feasibility",
            }), createElement("td", {
                colSpan: TimeLine__get_Duration(tl) - 1,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_4, elems_3, elems_2, elems_1;
                return append_1(singleton_1(createElement("tr", createObj(ofArray([["className", "main-headers"], (elems_4 = [createElement("td", createObj(ofArray([["colSpan", sourceDescriptionColumns], (elems_3 = ["Footprint", createElement("div", createObj(ofArray([["className", "show-all-scopes"], (elems_2 = [createElement("label", createObj(singleton((elems_1 = ["Alle Scopes", createElement("input", {
                    type: "checkbox",
                    defaultValue: state.ShowAllScopes,
                    onChange: (ev) => {
                        dispatch(new Msg(5, [ev.target.checked]));
                    },
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("td", {
                    className: "gap-col",
                }), createElement("td", {
                    colSpan: TimeLine__get_Duration(tl),
                    children: "Reduktionsszenarien",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => concat(map_1((tupledArg) => {
                    const scope = tupledArg[0];
                    return renderScope(Scope_toInt(scope), tl, tupledArg[1], (x) => {
                        dispatch(new Msg(6, [scope, x]));
                    });
                }, scopeEmissionList))));
            }));
        })), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))), createElement("table", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))), delay(() => {
            let elems_5, value_53;
            const matchValue_1 = state.FocusedEmissionSource;
            if (matchValue_1 != null) {
                const sourceId = matchValue_1[1];
                const scope_1 = matchValue_1[0];
                const dimensions = matchValue_1[2];
                const src = find((x_1) => equals(x_1.Id, sourceId), ScopeValues_get(scope_1, state.UserData.Emissions).Sources);
                return !isEmpty(src.Overrides) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["override-notes"])], ["style", createObj(toList(delay(() => append_1(singleton_1(["top", dimensions.Top + 28]), delay(() => singleton_1(["left", dimensions.Left - ~~((200 - dimensions.Width) / 2)]))))))], (elems_5 = [createElement("div", {
                    children: ["Kommentar:"],
                }), createElement("textarea", createObj(ofArray([["onBlur", (_arg_1) => {
                    dispatch(new Msg(6, [scope_1, new PlanValue(1, [sourceId, new SourceDataChange(5, [void 0])])]));
                }], ["onChange", (ev_1) => {
                    dispatch(new Msg(6, [scope_1, new PlanValue(1, [sourceId, new SourceDataChange(4, [ev_1.target.value])])]));
                }], (value_53 = src.OverrideNotes, ["ref", (e) => {
                    if (!(e == null) && !equals(e.value, value_53)) {
                        e.value = value_53;
                    }
                }])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))) : empty_1();
            }
            else {
                return empty_1();
            }
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", "upload"], (elems_10 = ["Upload Footprint:", createElement("div", createObj(ofArray([["className", "tooltip"], (elems_7 = [Tooltip_render(upload)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", "controls"], (elems_9 = toList(delay(() => {
        let elems_8;
        return append_1(singleton_1(createElement("label", createObj(ofArray([["for", "ExcelFileUpload"], (elems_8 = ["Datei auswählen", createElement("input", {
            id: "ExcelFileUpload",
            type: "file",
            onChange: (fileEvent) => {
                handleUpload((arg_3) => {
                    dispatch(new Msg(7, [new ImportEmissionSourcesFile(0, [arg_3[1]])]));
                }, fileEvent);
            },
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => singleton_1(createElement("a", {
            href: "Emissionsquellen-Vorlage.xltx",
            children: "(Vorlage-Datei herunterladen...)",
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))]);
}

