import { Record } from "../../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { list_type, array_type, uint8_type, enum_type, int32_type, record_type, string_type, class_type } from "../../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";
import { UserParameters_$reflection } from "../BaseTypes/Targets.fs.js";
import { UserData_$reflection } from "./UserData.fs.js";
import { EmissionSourceId_$reflection } from "../BaseTypes/Emissions.fs.js";

export class SavedStateInfo extends Record {
    constructor(Creation, Name) {
        super();
        this.Creation = Creation;
        this.Name = Name;
    }
}

export function SavedStateInfo_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.SavedStateInfo", [], SavedStateInfo, () => [["Creation", class_type("System.DateTime")], ["Name", string_type]]);
}

export class SavedState extends Record {
    constructor(Info, TargetType, ScopeSelection, UserParameters, UserData, LastId) {
        super();
        this.Info = Info;
        this.TargetType = (TargetType | 0);
        this.ScopeSelection = (ScopeSelection | 0);
        this.UserParameters = UserParameters;
        this.UserData = UserData;
        this.LastId = LastId;
    }
}

export function SavedState_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.SavedState", [], SavedState, () => [["Info", SavedStateInfo_$reflection()], ["TargetType", enum_type("AccelerateGreen.NetZeroScenario.DbModel.TargetType", int32_type, [["PotentialApproach", 0], ["AbsoluteContraction", 1], ["KmuApproach", 2], ["PhysicalIntensity", 3]])], ["ScopeSelection", enum_type("AccelerateGreen.NetZeroScenario.DbModel.ScopeSelection", int32_type, [["S1", 0], ["S2", 1], ["S12", 2], ["S3", 3]])], ["UserParameters", UserParameters_$reflection()], ["UserData", UserData_$reflection()], ["LastId", EmissionSourceId_$reflection()]]);
}

export class User extends Record {
    constructor(EmailAddress, PasswordHash) {
        super();
        this.EmailAddress = EmailAddress;
        this.PasswordHash = PasswordHash;
    }
}

export function User_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.User", [], User, () => [["EmailAddress", string_type], ["PasswordHash", array_type(uint8_type)]]);
}

export class Client extends Record {
    constructor(Id, Name, User, StateInfos) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.User = User;
        this.StateInfos = StateInfos;
    }
}

export function Client_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.Client", [], Client, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["User", User_$reflection()], ["StateInfos", list_type(SavedStateInfo_$reflection())]]);
}

export class Database extends Record {
    constructor(Clients) {
        super();
        this.Clients = Clients;
    }
}

export function Database_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.Database", [], Database, () => [["Clients", list_type(class_type("System.Guid"))]]);
}

