import { Percent_hundred } from "../BaseTypes/./Percent.fs.js";
import { YearModule_durationExcl } from "../BaseTypes/Year.fs.js";
import { TargetSetting_AbsoluteContraction_shortStartForReductionCalc } from "../Database/TargetSetting.fs.js";
import { TimeLine__get_LongDuration, TimeLine__get_ShortDuration } from "../BaseTypes/TimeLine.fs.js";
import { append, toList, singleton, skip, replicate, scan } from "../../Client/fable_modules/fable-library-js.4.16.0/Seq.js";
import { Helpers_toYearValues, Helpers_applyYearlyReduction } from "./Helpers.fs.js";
import { max } from "../../Client/fable_modules/fable-library-js.4.16.0/Double.js";

/**
 * Build the target reduction path (including the ending value, if it's different from last item of the path).
 */
export function build(_arg, totalEmissions) {
    const tl = _arg.TimeLine;
    const targets = _arg.Targets;
    const shortStart = (targets.Coverage.Short / Percent_hundred) * totalEmissions;
    const shortDiff = ((targets.ShortTermAmbition * (YearModule_durationExcl((_arg.TargetType === 1) ? TargetSetting_AbsoluteContraction_shortStartForReductionCalc(_arg.BaseYear) : tl.Short, tl.Long) - 1)) / Percent_hundred) * shortStart;
    const shortParameters = [shortStart, shortDiff, TimeLine__get_ShortDuration(tl) - 1];
    const longStart = shortStart - shortDiff;
    const longParameters = [longStart, longStart - (((100 - targets.LongTermAmbition) / Percent_hundred) * ((targets.Coverage.Long / Percent_hundred) * totalEmissions)), TimeLine__get_LongDuration(tl)];
    const reduceLinearly = (tupledArg) => {
        const duration = tupledArg[2] | 0;
        return scan(Helpers_applyYearlyReduction, tupledArg[0], replicate(duration, max(tupledArg[1], 0) / duration));
    };
    const shortTerm = reduceLinearly(shortParameters);
    const longTerm = (TimeLine__get_LongDuration(tl) > 0) ? skip(1, reduceLinearly(longParameters)) : singleton(longStart);
    return toList(Helpers_toYearValues(tl.Short)(append(shortTerm, longTerm)));
}

