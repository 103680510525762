import { Record } from "./fable_modules/fable-library-js.4.16.0/Types.js";
import { YearModule_next, YearModule_rangeExclToSeq, Year, Year_$reflection } from "../Shared/BaseTypes/Year.fs.js";
import { record_type, array_type, float64_type } from "./fable_modules/fable-library-js.4.16.0/Reflection.js";
import { concat, transpose, toArray, map as map_1, map2, toList, length as length_1, replicate, append } from "./fable_modules/fable-library-js.4.16.0/Seq.js";
import { last as last_1, head, map, append as append_1, reduce, length } from "./fable_modules/fable-library-js.4.16.0/List.js";
import { build as build_1 } from "../Shared/Calculations/TargetReductionPath.fs.js";
import { UserDataModule_getBaseYearEmissions } from "../Shared/Database/UserData.fs.js";
import { build as build_2 } from "../Shared/Calculations/PlanReductionPath.fs.js";
import { ScopeSelection_getList } from "../Shared/BaseTypes/ScopeHelpers.fs.js";
import { replicate as replicate_1 } from "./fable_modules/fable-library-js.4.16.0/Array.js";

export class ChartColumn extends Record {
    constructor(Year, Target, Emissions) {
        super();
        this.Year = Year;
        this.Target = Target;
        this.Emissions = Emissions;
    }
}

export function ChartColumn_$reflection() {
    return record_type("AccelerateGreen.WebUI.ChartData.ChartColumn", [], ChartColumn, () => [["Year", Year_$reflection()], ["Target", float64_type], ["Emissions", array_type(float64_type)]]);
}

export function ChartColumnModule_build(year, target, emissions) {
    return new ChartColumn(year, target, emissions);
}

export function fillToLength(len, value, s) {
    return append(s, replicate(len, value));
}

export function extendToMatchingLength(reference, value, s) {
    const lenDiff = (length(reference) - length_1(s)) | 0;
    if (lenDiff > 0) {
        return fillToLength(lenDiff, value, s);
    }
    else {
        return s;
    }
}

/**
 * Build columns (containing year, target, plan) and emissions sources.
 */
export function buildReductionPathYears(params$0027, scope, emissions) {
    const targetRedPath = build_1(params$0027, UserDataModule_getBaseYearEmissions(emissions)(scope));
    const patternInput = reduce((tupledArg_1, tupledArg_2) => [append_1(tupledArg_1[0], tupledArg_2[0]), append_1(tupledArg_1[1], tupledArg_2[1])], map((tupledArg) => {
        const emissions_2 = tupledArg[1];
        return [emissions_2.Sources, build_2(params$0027.Targets.Coverage, params$0027.TimeLine, emissions_2)];
    }, ScopeSelection_getList(scope, emissions)));
    return [toList(map2((t, p) => ChartColumnModule_build(t.Year, t.Value, p), targetRedPath, extendToMatchingLength(targetRedPath, new Float64Array([]), map_1(toArray, transpose(patternInput[1]))))), patternInput[0]];
}

export const fullYearRangeExcl = [new Year(2020), new Year(2051)];

/**
 * Extend column list to full range with empty/replicated data.
 */
export function extendToFullRange(cols) {
    const matchValue = head(cols);
    const last = last_1(cols);
    const first = matchValue;
    const emptySources = replicate_1(first.Emissions.length, 0, Float64Array);
    const buildColumns = (target, tupledArg) => map_1((yr) => ChartColumnModule_build(yr, target, emptySources), YearModule_rangeExclToSeq(tupledArg[0], tupledArg[1]));
    return concat([buildColumns(first.Target, [fullYearRangeExcl[0], first.Year]), cols, buildColumns(last.Target, [YearModule_next(last.Year), fullYearRangeExcl[1]])]);
}

