import { Union, Record } from "../../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { union_type, record_type, enum_type, int32_type } from "../../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";
import { Targets_$reflection } from "../Database/UserData.fs.js";
import { YearModule_countFrom, Year_$reflection } from "../BaseTypes/Year.fs.js";
import { TimeLine_$reflection } from "../BaseTypes/TimeLine.fs.js";
import { round } from "../../Client/fable_modules/fable-library-js.4.16.0/Util.js";
import { max } from "../../Client/fable_modules/fable-library-js.4.16.0/Double.js";
import { mapIndexed, map, zip } from "../../Client/fable_modules/fable-library-js.4.16.0/Seq.js";
import { YearValue$1 } from "../BaseTypes/Emissions.fs.js";

export class TargetParameters extends Record {
    constructor(TargetType, Targets, BaseYear, TimeLine) {
        super();
        this.TargetType = (TargetType | 0);
        this.Targets = Targets;
        this.BaseYear = BaseYear;
        this.TimeLine = TimeLine;
    }
}

export function TargetParameters_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.Calculations.TargetParameters", [], TargetParameters, () => [["TargetType", enum_type("AccelerateGreen.NetZeroScenario.DbModel.TargetType", int32_type, [["PotentialApproach", 0], ["AbsoluteContraction", 1], ["KmuApproach", 2], ["PhysicalIntensity", 3]])], ["Targets", Targets_$reflection()], ["BaseYear", Year_$reflection()], ["TimeLine", TimeLine_$reflection()]]);
}

/**
 * Round a float<'u> to the 'n'th digit after the comma.
 * Example: "roundToFloat 2 1203.7382<t> = 1203.74".
 */
export function Helpers_roundToFloat(n, x) {
    switch (n) {
        case 0:
            return round(x);
        case 1:
            return round(x * 10) / 10;
        case 2:
            return round(x * 100) / 100;
        default: {
            const m = Math.pow(10, n);
            return round(x * m) / m;
        }
    }
}

/**
 * Reduce 'rest' (float<'u>) once by the given yearly 'reduction' (float<'u/yr>), never going below zero.
 * Example: "applyYearlyReduction 1000.0<t> 25.0<t/yr> = 975.0<t>".
 */
export function Helpers_applyYearlyReduction(rest, reduction) {
    return max(rest - (reduction * 1), 0);
}

export function Helpers_toYearValues(start) {
    let f1;
    const source = YearModule_countFrom(start);
    f1 = ((source_1) => zip(source, source_1));
    return (arg) => map((tupledArg) => (new YearValue$1(tupledArg[0], tupledArg[1])), f1(arg));
}

/**
 * Map first 'idx' items with mapper1, the remaining ones with mapper2.
 * Example "mapSwitchAt 3 (fun x -> x * x) (fun _ -> 0) [1..5] = [1; 4; 9; 0; 0]".
 */
export function Seq_mapSwitchAt(idx, mapper1, mapper2) {
    return (source) => mapIndexed((i, v) => {
        if (i < idx) {
            return mapper1(v);
        }
        else {
            return mapper2(v);
        }
    }, source);
}

/**
 * Map first 'count' items with mapper, leave remaining untouched.
 * Example "mapFirstN 3 (fun x -> x * x) [1..5] = [1; 4; 9; 4; 5]
 */
export function Seq_mapFirstN(count, mapper) {
    return (source) => mapIndexed((i, v) => {
        if (i < count) {
            return mapper(v);
        }
        else {
            return v;
        }
    }, source);
}

export function Seq_mapStartingAt(start, mapper) {
    return (source) => mapIndexed((i, v) => {
        if (i < start) {
            return v;
        }
        else {
            return mapper(v);
        }
    }, source);
}

export class CutoffAtThreshold extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Above", "Nearest", "Below"];
    }
}

export function CutoffAtThreshold_$reflection() {
    return union_type("AccelerateGreen.NetZeroScenario.Calculations.CutoffAtThreshold", [], CutoffAtThreshold, () => [[], [], []]);
}

