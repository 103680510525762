import { substring, replace } from "../fable_modules/fable-library-js.4.16.0/String.js";
import { replace as replace_1 } from "../fable_modules/fable-library-js.4.16.0/RegExp.js";
import { parse } from "../fable_modules/fable-library-js.4.16.0/Double.js";
import { round } from "../fable_modules/fable-library-js.4.16.0/Util.js";

/**
 * Integer with max 'before' digits.
 * Example: intSpec 3 = (3, 0). (pattern "xxx")
 */
export function intSpec(before) {
    return [before, 0];
}

/**
 * Float with max 'before' digits before.
 * Example: floatOneDigitAfter 5 = (3, 1). (pattern "xxxxx.x")
 */
export function floatOneDigitAfter(before) {
    return [before, 1];
}

export const allowedIntChars = "0123456789";

export const allowedFloatChars = "0123456789.";

const numberRegex = /[^0-9\.]/gu;

function removeAllPoints(s) {
    return replace(s, ".", "");
}

export function fixAndParseFloat(_digitsBeforePoint, digitsAfterPoint, s) {
    const s_1 = replace_1(numberRegex, s, "");
    let fixedString;
    if (digitsAfterPoint === 0) {
        fixedString = removeAllPoints(s_1);
    }
    else {
        const matchValue = s_1.lastIndexOf(".") | 0;
        if (matchValue === -1) {
            fixedString = s_1;
        }
        else {
            const n = matchValue | 0;
            fixedString = (removeAllPoints(substring(s_1, 0, n)) + substring(s_1, n));
        }
    }
    if (fixedString.length === 0) {
        return 0;
    }
    else {
        const raw = parse(fixedString);
        switch (digitsAfterPoint) {
            case 0:
                return round(raw);
            case 1:
                return round(raw * 10) / 10;
            default: {
                const factor = Math.pow(10, digitsAfterPoint);
                return round(raw * factor) / factor;
            }
        }
    }
}

export function fixAndParseInt(spec_, spec__1, s) {
    const spec = [spec_, spec__1];
    return ~~fixAndParseFloat(spec[0], spec[1], s) | 0;
}

