import { Union, Record } from "../fable_modules/fable-library-js.4.16.0/Types.js";
import { Year_$reflection } from "../../Shared/BaseTypes/Year.fs.js";
import { class_type, bool_type, list_type, array_type, uint8_type, union_type, option_type, enum_type, int32_type, string_type, record_type, float64_type } from "../fable_modules/fable-library-js.4.16.0/Reflection.js";
import { StatusArea_Msg_$reflection, Dimensions_$reflection } from "./State.fs.js";
import { EmissionSourceId_$reflection } from "../../Shared/BaseTypes/Emissions.fs.js";
import { ServerStatus_$reflection, ImportedEmissionSource_$reflection } from "../../Shared/ServerApi.fs.js";
import { SavedState_$reflection, SavedStateInfo_$reflection } from "../../Shared/Database/Database.fs.js";
import { Scope_$reflection } from "../../Shared/BaseTypes/Scope.fs.js";
import { TimeLineItem_$reflection } from "../../Shared/Database/TargetSetting.fs.js";

export class OverrideChange extends Record {
    constructor(Year, NewValue) {
        super();
        this.Year = Year;
        this.NewValue = NewValue;
    }
}

export function OverrideChange_$reflection() {
    return record_type("AccelerateGreen.WebUI.OverrideChange", [], OverrideChange, () => [["Year", Year_$reflection()], ["NewValue", float64_type]]);
}

export class SourceDataChange extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Name", "Feasibility", "Footprint", "Override", "OverrideNote", "OverrideFocus", "DeleteSource"];
    }
}

export function SourceDataChange_$reflection() {
    return union_type("AccelerateGreen.WebUI.SourceDataChange", [], SourceDataChange, () => [[["Item", string_type]], [["Item", enum_type("AccelerateGreen.NetZeroScenario.DbModel.Difficulty", int32_type, [["Easy", 1], ["Moderate", 2], ["Hard", 3]])]], [["Item", float64_type]], [["Item", OverrideChange_$reflection()]], [["Item", string_type]], [["Item", option_type(Dimensions_$reflection())]], []]);
}

export class ShortOrLong extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Short", "Long"];
    }
}

export function ShortOrLong_$reflection() {
    return union_type("AccelerateGreen.WebUI.ShortOrLong", [], ShortOrLong, () => [[], []]);
}

export class PlanValue extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Average", "SourceData", "AddSource", "SortList"];
    }
}

export function PlanValue_$reflection() {
    return union_type("AccelerateGreen.WebUI.PlanValue", [], PlanValue, () => [[["Item1", ShortOrLong_$reflection()], ["Item2", float64_type]], [["Item1", EmissionSourceId_$reflection()], ["Item2", SourceDataChange_$reflection()]], [], []]);
}

export class ImportEmissionSourcesFile extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Upload", "Import", "ImportError"];
    }
}

export function ImportEmissionSourcesFile_$reflection() {
    return union_type("AccelerateGreen.WebUI.ImportEmissionSourcesFile", [], ImportEmissionSourcesFile, () => [[["Item", array_type(uint8_type)]], [["Item", list_type(ImportedEmissionSource_$reflection())]], [["Item", string_type]]]);
}

export class CoverageOrAmbition extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Coverage", "Ambition"];
    }
}

export function CoverageOrAmbition_$reflection() {
    return union_type("AccelerateGreen.WebUI.CoverageOrAmbition", [], CoverageOrAmbition, () => [[], []]);
}

export class StateStorage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NameChanged", "SelectionChanged", "LoadList", "LoadedList", "Load", "Loaded", "Save", "Delete"];
    }
}

export function StateStorage_$reflection() {
    return union_type("AccelerateGreen.WebUI.StateStorage", [], StateStorage, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", list_type(SavedStateInfo_$reflection())]], [], [["Item", SavedState_$reflection()]], [], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["TargetTypeChanged", "ScopeChanged", "TotalEmissionsChanged", "TimeLineChanged", "UserParametersChanged", "ShowAllScopesChanged", "PlanValueChanged", "ImportEmissionSourcesFile", "ExportChartData", "StateStorage", "BackendError", "BackendReachable", "StatusArea"];
    }
}

export function Msg_$reflection() {
    return union_type("AccelerateGreen.WebUI.Msg", [], Msg, () => [[["Item", enum_type("AccelerateGreen.NetZeroScenario.DbModel.TargetType", int32_type, [["PotentialApproach", 0], ["AbsoluteContraction", 1], ["KmuApproach", 2], ["PhysicalIntensity", 3]])]], [["Item", enum_type("AccelerateGreen.NetZeroScenario.DbModel.ScopeSelection", int32_type, [["S1", 0], ["S2", 1], ["S12", 2], ["S3", 3]])]], [["Item1", Scope_$reflection()], ["Item2", float64_type]], [["Item1", TimeLineItem_$reflection()], ["Item2", Year_$reflection()]], [["Item1", ShortOrLong_$reflection()], ["Item2", CoverageOrAmbition_$reflection()], ["Item3", option_type(enum_type("AccelerateGreen.NetZeroScenario.DbModel.ScopeSelection", int32_type, [["S1", 0], ["S2", 1], ["S12", 2], ["S3", 3]]))], ["Item4", float64_type]], [["Item", bool_type]], [["Item1", Scope_$reflection()], ["Item2", PlanValue_$reflection()]], [["Item", ImportEmissionSourcesFile_$reflection()]], [], [["Item", StateStorage_$reflection()]], [["Item", class_type("System.Exception")]], [["Item", ServerStatus_$reflection()]], [["Item", StatusArea_Msg_$reflection()]]]);
}

