import { Union } from "../../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { union_type, int32_type } from "../../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";
import { map, initializeInfinite } from "../../Client/fable_modules/fable-library-js.4.16.0/Seq.js";
import { rangeDouble } from "../../Client/fable_modules/fable-library-js.4.16.0/Range.js";

export class Year extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Year"];
    }
}

export function Year_$reflection() {
    return union_type("AccelerateGreen.NetZeroScenario.DbModel.Year", [], Year, () => [[["Item", int32_type]]]);
}

/**
 * Move a given number of steps (i.e. years) on the year axis.
 * Example: "step 10<yr> (Year 2000) = Year 2010".
 */
export function YearModule_step(years, _arg) {
    return new Year(_arg.fields[0] + years);
}

export const YearModule_patternInput$004021 = [(arg10$0040) => YearModule_step(-1, arg10$0040), (arg10$0040_1) => YearModule_step(1, arg10$0040_1)];

export const YearModule_prev = YearModule_patternInput$004021[0];

export const YearModule_next = YearModule_patternInput$004021[1];

export function YearModule_countFrom(_arg) {
    return initializeInfinite((arg) => (new Year(_arg.fields[0] + arg)));
}

/**
 * Get duration of year range from 'start' to 'endExcl' (excluded) as int<yr>.
 * Example: "durationExcl (Year 2020) (Year 2031) = 11<yr>".
 */
export function YearModule_durationExcl(_arg, _arg_1) {
    return (_arg_1.fields[0] - _arg.fields[0]) * 1;
}

/**
 * Get sequence of year numbers from 'start' to 'endExcl' (excluded).
 * Example: "rangeExclToSeq (Year 2020) (Year 2023) = [Year 2020; Year 2021; Year 2022]".
 */
export function YearModule_rangeExclToSeq(_arg, _arg_1) {
    return map((Item) => (new Year(Item)), rangeDouble(_arg.fields[0], 1, _arg_1.fields[0] - 1));
}

