import { reverse, map, partition, item } from "../fable_modules/fable-library-js.4.16.0/Array.js";
import { Interop_reactApi } from "../fable_modules/Feliz.Recharts.4.2.0/../Feliz.2.7.0/Interop.fs.js";
import { ResponsiveContainer, ComposedChart, Line, CartesianGrid, Tooltip, YAxis, XAxis, Bar } from "recharts";
import { comparePrimitives, curry2, int32ToString, createObj, round } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { createElement } from "react";
import { toArray, map as map_1, max as max_1, empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.16.0/Seq.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { mapIndexed, singleton as singleton_1, ofArray } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { defaultOf } from "../fable_modules/Feliz.2.7.0/../fable-library-js.4.16.0/Util.js";
import { Percent_hundred } from "../../Shared/BaseTypes/Percent.fs.js";
import { Percent_hundred as Percent_hundred_1 } from "../../Shared/BaseTypes/./Percent.fs.js";
import { Float_renderAsInt, Helpers_getSupportPoints } from "../UIHelpers.fs.js";
import { defaultArg } from "../fable_modules/fable-library-js.4.16.0/Option.js";
import { join } from "../fable_modules/fable-library-js.4.16.0/String.js";
import { extendToFullRange, buildReductionPathYears } from "../ChartData.fs.js";
import { ScopeSelectionValues_get } from "../../Shared/BaseTypes/ScopeHelpers.fs.js";
import { Targets } from "../../Shared/Database/UserData.fs.js";
import { TargetSetting_KmuApproach_scope3, TargetSetting_KmuApproach_scopes1and2, TargetSetting_AbsoluteContraction_getTargets } from "../../Shared/Database/TargetSetting.fs.js";
import { TargetTypeValues_get } from "../../Shared/BaseTypes/Targets.fs.js";
import { TargetParameters } from "../../Shared/Calculations/Helpers.fs.js";
import { renderPanel } from "../Controls/Helpers.fs.js";
import { Msg } from "../Base/Msg.fs.js";
import logo from "../style/logo.png";

const Color_colors = ["#02637D", "#37C3D5", "#78D0D8", "#999999", "#5F5F5F", "#C24646", "#DE5855", "#FF8664", "#005F64", "#03B57F", "#41DBC4"];

function Color_forIndex(idx) {
    return item(idx % Color_colors.length, Color_colors);
}

function renderBarTemplate(name, color, getter) {
    return Interop_reactApi.createElement(Bar, {
        dataKey: getter,
        name: name,
        stroke: "#8884d8",
        fillOpacity: 1,
        fill: color,
        stackId: "a",
    });
}

const lineLabel = "SOLL";

const lineColor = "#C24646";

function renderTooltip(input) {
    let elems_1;
    const input_1 = input;
    if (input_1.active) {
        const label = input_1.label;
        const patternInput = partition((tupledArg) => (tupledArg[0] === lineLabel), map((x) => [x.name, x.color, x.value], input_1.payload));
        const items_2 = reverse(patternInput[1].filter((tupledArg_1) => (round(tupledArg_1[2]) > 0)));
        const renderItem = (lineItem, tupledArg_2) => createElement("div", createObj(toList(delay(() => append(singleton(["style", {
            color: tupledArg_2[1],
        }]), delay(() => append(lineItem ? singleton(["className", "line-item"]) : empty(), delay(() => {
            let elems;
            return singleton((elems = [tupledArg_2[0], createElement("span", {
                children: [int32ToString(~~round(tupledArg_2[2]))],
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))]));
        }))))))));
        return createElement("div", createObj(ofArray([["className", "chart-tooltip"], (elems_1 = toList(delay(() => append(singleton(createElement("label", {
            children: Interop_reactApi_1.Children.toArray([label]),
        })), delay(() => append(singleton(renderItem(true, item(0, patternInput[0]))), delay(() => map(curry2(renderItem)(false), items_2))))))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])));
    }
    else {
        return defaultOf();
    }
}

function renderChart(targetPath, sources) {
    let value_3, elements;
    const maxVisibleValue = ~~((value_3 = (((Percent_hundred + 5) / Percent_hundred_1) * max_1(map_1((_arg) => _arg.Target, targetPath), {
        Compare: comparePrimitives,
    })), Math.ceil(value_3))) | 0;
    const maxVisibleValue_1 = ((maxVisibleValue !== 0) ? maxVisibleValue : 1000) | 0;
    const supportingPoints = Helpers_getSupportPoints(toList(map_1((_arg_1) => _arg_1.Target, targetPath)));
    const properties_6 = ofArray([["data", toArray(targetPath)], ["margin", {
        top: defaultArg(10, 0),
        right: defaultArg(30, 0),
        left: defaultArg(void 0, 0),
        bottom: defaultArg(void 0, 0),
    }], ["reverseStackOrder", true], (elements = toList(delay(() => append(singleton(Interop_reactApi.createElement(XAxis, {
        dataKey: (x_1) => x_1.Year.fields[0],
        angle: -45,
        textAnchor: "end",
        height: 45,
    })), delay(() => {
        let properties_1;
        return append(singleton((properties_1 = singleton_1(["domain", [0, maxVisibleValue_1]]), Interop_reactApi.createElement(YAxis, createObj(properties_1)))), delay(() => append(singleton(Interop_reactApi.createElement(Tooltip, {
            content: renderTooltip,
        })), delay(() => {
            let properties_3;
            return append(singleton((properties_3 = singleton_1(["strokeDasharray", join(" ", map(int32ToString, new Int32Array([3, 3])))]), Interop_reactApi.createElement(CartesianGrid, createObj(properties_3)))), delay(() => append(mapIndexed((i_1, s) => renderBarTemplate(s.Name, Color_forIndex(i_1), (cc) => {
                if ((0 <= i_1) && (i_1 < cc.Emissions.length)) {
                    return item(i_1, cc.Emissions) / 1;
                }
                else {
                    return 0;
                }
            }), sources), delay(() => singleton(Interop_reactApi.createElement(Line, {
                dataKey: (x_2) => (x_2.Target / 1),
                name: lineLabel,
                stroke: lineColor,
                strokeWidth: 2,
                label: (data) => {
                    if (item(data.index, supportingPoints)) {
                        return createElement("text", {
                            x: data.x,
                            y: data.y - 5,
                            dominantBaseline: "text-after-edge",
                            textAnchor: "middle",
                            children: Float_renderAsInt(data.value),
                        });
                    }
                    else {
                        return defaultOf();
                    }
                },
            }))))));
        }))));
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]);
    return Interop_reactApi.createElement(ComposedChart, createObj(properties_6));
}

export function buildChartData(state) {
    let matchValue, targets, userParams, matchValue_1;
    const patternInput = buildReductionPathYears(new TargetParameters(state.TargetType, (matchValue = (state.TargetType | 0), (matchValue === 0) ? ((targets = state.UserParameters.PotentialApproach, new Targets(targets.Coverage, ScopeSelectionValues_get(state.ScopeSelection, targets.ShortTermAmbitions), targets.LongTermAmbition))) : ((matchValue === 1) ? TargetSetting_AbsoluteContraction_getTargets(state.ScopeSelection) : ((matchValue === 2) ? ((userParams = state.UserParameters.KmuApproach, (matchValue_1 = (state.ScopeSelection | 0), (matchValue_1 === 0) ? TargetSetting_KmuApproach_scopes1and2(state.UserData.TimeLine.KmuApproach.Short) : ((matchValue_1 === 1) ? TargetSetting_KmuApproach_scopes1and2(state.UserData.TimeLine.KmuApproach.Short) : ((matchValue_1 === 2) ? TargetSetting_KmuApproach_scopes1and2(state.UserData.TimeLine.KmuApproach.Short) : ((matchValue_1 === 3) ? TargetSetting_KmuApproach_scope3(userParams.Coverage, userParams.Ambition) : (() => {
        throw new Error("Unknown scope selection.");
    })())))))) : (() => {
        throw new Error("Unknown target type.");
    })()))), state.UserData.BaseYear, TargetTypeValues_get(state.TargetType, state.UserData.TimeLine)), state.ScopeSelection, state.UserData.Emissions);
    return [extendToFullRange(patternInput[0]), patternInput[1]];
}

export function render(state, dispatch) {
    let value_5, properties_2;
    const patternInput = buildChartData(state);
    return renderPanel("chart", void 0, [createElement("button", {
        children: "Diagrammdaten herunterladen...",
        onClick: (_arg) => {
            dispatch(new Msg(8, []));
        },
    }), createElement("img", createObj(ofArray([["className", "chart-logo"], (value_5 = logo, ["src", value_5]), ["onClick", (_arg_1) => {
        document.body.classList.toggle("branding");
    }]]))), (properties_2 = singleton_1(["children", renderChart(patternInput[0], patternInput[1])]), Interop_reactApi.createElement(ResponsiveContainer, createObj(properties_2)))]);
}

