import { State } from "../Base/State.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { singleton } from "../fable_modules/fable-library-js.4.16.0/AsyncBuilder.js";
import { sleep } from "../fable_modules/fable-library-js.4.16.0/Async.js";
import { Msg } from "../Base/Msg.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { createElement } from "react";
import { defaultOf } from "../fable_modules/Feliz.2.7.0/../fable-library-js.4.16.0/Util.js";

const serverConnectionRetryDelay = 10;

let isRetryDelayRunning = false;

export function update(backend, msg, state) {
    switch (msg.tag) {
        case 10: {
            const e_1 = msg.fields[0];
            return [new State(state.Storage, false, state.TargetType, state.ScopeSelection, state.UserParameters, state.UserData, state.ShowAllScopes, state.FocusedEmissionSource, state.StatusArea), !isRetryDelayRunning ? ((isRetryDelayRunning = true, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, () => singleton.Delay(() => singleton.Bind(sleep(serverConnectionRetryDelay * 1000), () => {
                isRetryDelayRunning = false;
                return singleton.ReturnFrom(backend.getServerStatus());
            })), void 0, (Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(10, [Item_1]))))) : Cmd_none()];
        }
        case 11:
            return [new State(state.Storage, true, state.TargetType, state.ScopeSelection, state.UserParameters, state.UserData, state.ShowAllScopes, state.FocusedEmissionSource, state.StatusArea), Cmd_none()];
        default:
            throw new Error("Unknown message type.");
    }
}

export function render(state, _dispatch) {
    if (!state.IsServerReachable) {
        return createElement("div", {
            className: "server-status-alert",
            children: "Die Verbindung zum Server ist fehlgeschlagen.",
        });
    }
    else {
        return defaultOf();
    }
}

