import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.32.0/./Remoting.fs.js";
import { RemotingModule_createApi } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Api_$reflection } from "../Shared/ServerApi.fs.js";
import { update as update_1, init as init_1 } from "./LocalStorage.fs.js";
import { render as render_1, update as update_2, initCmd, init as init_2 } from "./Page/Page.fs.js";
import { ProgramModule_mkProgram, ProgramModule_run } from "./fable_modules/Fable.Elmish.4.1.0/program.fs.js";
import { Program_withReactSynchronous } from "./fable_modules/Fable.Elmish.React.4.0.0/react.fs.js";
import { uncurry2 } from "./fable_modules/fable-library-js.4.16.0/Util.js";
import "./style/index.scss";

export const api = Remoting_buildProxy_64DC51C(RemotingModule_createApi(), Api_$reflection());


export const version = "1.0.0";

export const isAuth = (() => {
    const loc = window.location;
    return (loc.hostname === "localhost") ? true : (loc.search.indexOf("?9dcf3f1f") === 0);
})();

export const init = () => init_1(init_2, initCmd, void 0);

export const update = (msg_1) => ((state_1) => update_1((msg, state) => update_2(api, msg, state), msg_1, state_1));

export const render = (state) => ((dispatch) => render_1(version, state, dispatch));

if (isAuth) {
    ProgramModule_run(Program_withReactSynchronous("app", ProgramModule_mkProgram(init, uncurry2(update), uncurry2(render))));
}

