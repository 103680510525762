import { Union, Record } from "../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { array_type, uint8_type, lambda_type, class_type, unit_type, list_type, union_type, int32_type, record_type, float64_type, string_type } from "../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";
import { Scope_$reflection } from "./BaseTypes/Scope.fs.js";
import { Year_$reflection } from "./BaseTypes/Year.fs.js";
import { SavedState_$reflection, SavedStateInfo_$reflection } from "./Database/Database.fs.js";
import { FSharpResult$2 } from "../Client/fable_modules/fable-library-js.4.16.0/Result.js";

export class ImportedEmissionSource extends Record {
    constructor(Name, Scope, FootPrint) {
        super();
        this.Name = Name;
        this.Scope = Scope;
        this.FootPrint = FootPrint;
    }
}

export function ImportedEmissionSource_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.Server.ImportedEmissionSource", [], ImportedEmissionSource, () => [["Name", string_type], ["Scope", Scope_$reflection()], ["FootPrint", float64_type]]);
}

export class UserLoginResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Error", "Success"];
    }
}

export function UserLoginResult_$reflection() {
    return union_type("AccelerateGreen.NetZeroScenario.Server.UserLoginResult", [], UserLoginResult, () => [[], [["userId", int32_type]]]);
}

export class ReductionPathColumn extends Record {
    constructor(Year, Target, Emissions) {
        super();
        this.Year = Year;
        this.Target = Target;
        this.Emissions = Emissions;
    }
}

export function ReductionPathColumn_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.Server.ReductionPathColumn", [], ReductionPathColumn, () => [["Year", Year_$reflection()], ["Target", float64_type], ["Emissions", list_type(float64_type)]]);
}

export class ReductionPathExport extends Record {
    constructor(EmissionSourceNames, Columns) {
        super();
        this.EmissionSourceNames = EmissionSourceNames;
        this.Columns = Columns;
    }
}

export function ReductionPathExport_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.Server.ReductionPathExport", [], ReductionPathExport, () => [["EmissionSourceNames", list_type(string_type)], ["Columns", list_type(ReductionPathColumn_$reflection())]]);
}

export class ServerStatus extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Ready"];
    }
}

export function ServerStatus_$reflection() {
    return union_type("AccelerateGreen.NetZeroScenario.Server.ServerStatus", [], ServerStatus, () => [[]]);
}

export class Api extends Record {
    constructor(getServerStatus, getListOfSavedStates, saveState, loadState, deleteState, uploadEmissionSourcesFile, downloadReductionPathFile) {
        super();
        this.getServerStatus = getServerStatus;
        this.getListOfSavedStates = getListOfSavedStates;
        this.saveState = saveState;
        this.loadState = loadState;
        this.deleteState = deleteState;
        this.uploadEmissionSourcesFile = uploadEmissionSourcesFile;
        this.downloadReductionPathFile = downloadReductionPathFile;
    }
}

export function Api_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.Server.Api", [], Api, () => [["getServerStatus", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [ServerStatus_$reflection()]))], ["getListOfSavedStates", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(SavedStateInfo_$reflection())]))], ["saveState", lambda_type(SavedState_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))], ["loadState", lambda_type(SavedStateInfo_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [SavedState_$reflection()]))], ["deleteState", lambda_type(SavedStateInfo_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))], ["uploadEmissionSourcesFile", lambda_type(array_type(uint8_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ImportedEmissionSource_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(ImportedEmissionSource_$reflection())]], [["ErrorValue", string_type]]])]))], ["downloadReductionPathFile", lambda_type(ReductionPathExport_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(uint8_type)]))]]);
}

