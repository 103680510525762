import { singleton } from "../fable_modules/fable-library-js.4.16.0/AsyncBuilder.js";
import { startImmediate, sleep } from "../fable_modules/fable-library-js.4.16.0/Async.js";
import { ofArray, map, isEmpty, singleton as singleton_1, append, filter } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { StatusArea_Msg, StatusArea_State } from "../Base/State.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.7.0/../fable-library-js.4.16.0/Util.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.16.0/String.js";
import { empty, singleton as singleton_2, append as append_1, delay as delay_1, toList } from "../fable_modules/fable-library-js.4.16.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";

function delay(ms, result) {
    return singleton.Delay(() => singleton.Bind(sleep(ms), () => singleton.Return(result)));
}

let curId = 0;

export function update(msg, state) {
    let dur;
    if (msg.tag === 1) {
        return [new StatusArea_State(filter((tupledArg) => (tupledArg[0] !== msg.fields[0]), state.Messages)), Cmd_none()];
    }
    else {
        const m = msg.fields[0];
        const id = curId | 0;
        curId = ((curId + 1) | 0);
        return [new StatusArea_State(append(state.Messages, singleton_1([id, m]))), (m.Duration > 0) ? ((dur = (~~m.Duration | 0), Cmd_OfAsyncWith_perform((computation) => {
            startImmediate(computation);
        }, (result) => delay(dur, result), id, (Item) => (new StatusArea_Msg(1, [Item]))))) : Cmd_none()];
    }
}

export function render(state, dispatch) {
    let elems_1;
    if (isEmpty(state.Messages)) {
        return defaultOf();
    }
    else {
        return createElement("div", createObj(ofArray([["className", "status-area"], (elems_1 = map((tupledArg) => {
            let elems;
            const msg = tupledArg[1];
            return createElement("div", createObj(ofArray([["className", toText(printf("%A"))(msg.Level).toLocaleLowerCase()], (elems = toList(delay_1(() => append_1(map((children) => createElement("div", {
                children: [children],
            }), msg.Text), delay_1(() => ((msg.Duration === 0) ? singleton_2(createElement("button", {
                children: "Schliessen",
                onClick: (_arg) => {
                    dispatch(new StatusArea_Msg(1, [tupledArg[0]]));
                },
            })) : empty()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
        }, state.Messages), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }
}

