import { append, scan, toList, take, map } from "../../Client/fable_modules/fable-library-js.4.16.0/Seq.js";
import { defaultArg } from "../../Client/fable_modules/fable-library-js.4.16.0/Option.js";
import { tryFind } from "../../Client/fable_modules/fable-library-js.4.16.0/Map.js";
import { YearModule_countFrom } from "../BaseTypes/Year.fs.js";
import { map as map_1, tail, head, empty, isEmpty } from "../../Client/fable_modules/fable-library-js.4.16.0/List.js";
import { Helpers_applyYearlyReduction, Seq_mapFirstN, CutoffAtThreshold, Seq_mapStartingAt } from "./Helpers.fs.js";
import { Percent_hundred } from "../BaseTypes/./Percent.fs.js";
import { comparePrimitives } from "../../Client/fable_modules/fable-library-js.4.16.0/Util.js";
import { TimeLine__get_LongDuration, TimeLine__get_ShortDuration } from "../BaseTypes/TimeLine.fs.js";
import { max } from "../../Client/fable_modules/fable-library-js.4.16.0/Double.js";

function buildPlan(start, duration, default$0027, overrides) {
    return map((yr) => defaultArg(tryFind(yr, overrides), default$0027), take(duration, YearModule_countFrom(start)));
}

export function build(coverage, tl, emissions) {
    let cutOff_1, target, go, first;
    const plan = emissions.ReductionPlan;
    const sources = emissions.Sources;
    if (isEmpty(sources)) {
        return empty();
    }
    else {
        return toList(map(toList, Seq_mapStartingAt(((cutOff_1 = (new CutoffAtThreshold(0, [])), (target = ((coverage.Short / Percent_hundred) * emissions.BaseYearEmissions), (go = ((inputs) => {
            const inputs_1 = inputs;
            const sum = inputs_1[1];
            return (_arg_1) => {
                if (!isEmpty(_arg_1)) {
                    const newSum = sum + head(_arg_1);
                    const nextInputs = [inputs_1[0] + 1, newSum];
                    const matchValue = comparePrimitives(target, newSum) | 0;
                    switch (matchValue) {
                        case -1:
                            return (cutOff_1.tag === 0) ? nextInputs : ((cutOff_1.tag === 1) ? (((target - sum) <= (newSum - target)) ? inputs_1 : nextInputs) : inputs_1);
                        case 0:
                            return nextInputs;
                        case 1:
                            return go(nextInputs)(tail(_arg_1));
                        default:
                            throw new Error("This must never happen.");
                    }
                }
                else {
                    return inputs_1;
                }
            };
        }), (first = [0, 0], (target === 0) ? first : go(first)(map_1((_arg) => _arg.FootPrint, sources)))))))[0], Seq_mapFirstN(TimeLine__get_ShortDuration(tl), (_arg_2) => 0))(map((source) => {
            const startValue = source.FootPrint;
            return map((e_1) => max(0, e_1), scan(Helpers_applyYearlyReduction, startValue, map((reduction) => ((reduction / Percent_hundred) * startValue), append(buildPlan(tl.Short, TimeLine__get_ShortDuration(tl), plan.Short, source.Overrides), buildPlan(tl.Long, TimeLine__get_LongDuration(tl), plan.Long, source.Overrides)))));
        }, sources))));
    }
}

