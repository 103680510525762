import { Record } from "../../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { TimeLine, TimeLine_$reflection } from "../BaseTypes/TimeLine.fs.js";
import { TargetTypeValues_updateAll, TargetTypeValues$1_$reflection } from "../BaseTypes/Targets.fs.js";
import { Year_$reflection } from "../BaseTypes/Year.fs.js";
import { TermValuesGeneric$2_$reflection, ScopeEmissions_$reflection } from "../BaseTypes/Emissions.fs.js";
import { ScopeValues$1_$reflection } from "../BaseTypes/Scope.fs.js";
import { float64_type, record_type } from "../../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";

export class UserData extends Record {
    constructor(TimeLine, BaseYear, Emissions) {
        super();
        this.TimeLine = TimeLine;
        this.BaseYear = BaseYear;
        this.Emissions = Emissions;
    }
}

export function UserData_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.UserData", [], UserData, () => [["TimeLine", TargetTypeValues$1_$reflection(TimeLine_$reflection())], ["BaseYear", Year_$reflection()], ["Emissions", ScopeValues$1_$reflection(ScopeEmissions_$reflection())]]);
}

export function UserDataModule_getBaseYearEmissions(e) {
    const get$ = (se) => se.BaseYearEmissions;
    return (_arg) => {
        switch (_arg) {
            case 0:
                return get$(e.Scope1);
            case 1:
                return get$(e.Scope2);
            case 2:
                return get$(e.Scope1) + get$(e.Scope2);
            case 3:
                return get$(e.Scope3);
            default:
                throw new Error("Unhandled ScopeSelection");
        }
    };
}

/**
 * Set base year as well as 'TimeLine.Short' for all target types to 'newValue'.
 */
export function UserDataModule_setBaseYear(newValue, ud) {
    return new UserData(TargetTypeValues_updateAll((tl) => (new TimeLine(newValue, tl.Long, tl.Rest)), ud.TimeLine), newValue, ud.Emissions);
}

export class Targets extends Record {
    constructor(Coverage, ShortTermAmbition, LongTermAmbition) {
        super();
        this.Coverage = Coverage;
        this.ShortTermAmbition = ShortTermAmbition;
        this.LongTermAmbition = LongTermAmbition;
    }
}

export function Targets_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.Targets", [], Targets, () => [["Coverage", TermValuesGeneric$2_$reflection(float64_type, float64_type)], ["ShortTermAmbition", float64_type], ["LongTermAmbition", float64_type]]);
}

