import { isNullOrEmpty } from "./fable_modules/fable-library-js.4.16.0/String.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_serialize, Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { int32_type } from "./fable_modules/fable-library-js.4.16.0/Reflection.js";
import { State_$reflection } from "./Base/State.fs.js";
import { map, defaultArgWith } from "./fable_modules/fable-library-js.4.16.0/Option.js";

const stateId = "State";

const stateVersionId = "Version";

const stateVersion = 1;

function initFromLocalStorage() {
    let matchValue, matchValue_1;
    let version;
    const s = localStorage.getItem(stateVersionId);
    version = (!isNullOrEmpty(s) ? ((matchValue = SimpleJson_tryParse(s), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(int32_type)) : (() => {
        throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
    })())) : void 0);
    let matchResult, v_1;
    if (version != null) {
        if (version === stateVersion) {
            matchResult = 0;
            v_1 = version;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            const s_1 = localStorage.getItem(stateId);
            if (!isNullOrEmpty(s_1)) {
                return (matchValue_1 = SimpleJson_tryParse(s_1), (matchValue_1 != null) ? Convert_fromJson(matchValue_1, createTypeInfo(State_$reflection())) : (() => {
                    throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                })());
            }
            else {
                return void 0;
            }
        }
        default: {
            const s_2 = Convert_serialize(stateVersion, createTypeInfo(int32_type));
            localStorage.setItem(stateVersionId, s_2);
            return void 0;
        }
    }
}

function saveToStorage(s) {
    const s_1 = Convert_serialize(s, createTypeInfo(State_$reflection()));
    localStorage.setItem(stateId, s_1);
}

export function init(wrappedInit, cmdAfterLoadFromLocalStorage, unitVar) {
    return defaultArgWith(map((state) => [state, cmdAfterLoadFromLocalStorage], initFromLocalStorage()), wrappedInit);
}

export function update(wrappedUpdate, msg, state) {
    const res = wrappedUpdate(msg, state);
    saveToStorage(res[0]);
    return res;
}

