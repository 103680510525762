import { Union } from "../../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { union_type } from "../../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";
import { now as now_1, year } from "../../Client/fable_modules/fable-library-js.4.16.0/Date.js";
import { YearModule_durationExcl, YearModule_next, YearModule_step, Year } from "../BaseTypes/Year.fs.js";
import { max, compare, min } from "../../Client/fable_modules/fable-library-js.4.16.0/Util.js";
import { TermValuesGeneric$2 } from "../BaseTypes/Emissions.fs.js";
import { Targets } from "./UserData.fs.js";
import { Percent_twoThirds } from "../BaseTypes/Percent.fs.js";
import { TimeLineModule_adjustLong, TimeLine, TimeLineModule_offsetBy } from "../BaseTypes/TimeLine.fs.js";

export class TimeLineItem extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["BaseYear", "ShortYear", "ShortEndYear", "LongEndYear"];
    }
}

export function TimeLineItem_$reflection() {
    return union_type("AccelerateGreen.NetZeroScenario.DbModel.TimeLineItem", [], TimeLineItem, () => [[], [], [], []]);
}

export const TargetSetting_currentYear = new Year(year(now_1()));

export function TargetSetting_baseYearRange(submissionYear) {
    return [YearModule_step(-2, submissionYear), YearModule_step(-1, submissionYear)];
}

const TargetSetting_AbsoluteContraction_referenceYear = new Year(2020);

export function TargetSetting_AbsoluteContraction_shortStartForReductionCalc(baseYear) {
    return min(compare, TargetSetting_AbsoluteContraction_referenceYear, baseYear);
}

export const TargetSetting_AbsoluteContraction_scopes1and2 = new Targets(new TermValuesGeneric$2(95, 95), 4.2, 90);

export const TargetSetting_AbsoluteContraction_scope3 = new Targets(new TermValuesGeneric$2(Percent_twoThirds, 90), 2.5, 90);

export function TargetSetting_AbsoluteContraction_getTargets(_arg) {
    switch (_arg) {
        case 0:
        case 1:
        case 2:
            return TargetSetting_AbsoluteContraction_scopes1and2;
        case 3:
            return TargetSetting_AbsoluteContraction_scope3;
        default:
            throw new Error("Unhandled ScopeSelection.");
    }
}

export function TargetSetting_KmuApproach_ShortTerm_ambitionScope1and2ByStartYear(_arg) {
    switch (_arg.fields[0]) {
        case 2018:
            return 50.4;
        case 2019:
            return 46.2;
        case 2020:
            return 42;
        case 2021:
            return 42;
        case 2022:
            return 42;
        default:
            throw new Error("Start year is outside of 2018..2022 range.");
    }
}

export const TargetSetting_KmuApproach_ShortTerm_endYear = new Year(2030);

export const TargetSetting_KmuApproach_longTermStartYear = YearModule_next(TargetSetting_KmuApproach_ShortTerm_endYear);

export function TargetSetting_KmuApproach_scopes1and2(shortTermStart) {
    const bind$0040 = TargetSetting_AbsoluteContraction_scopes1and2;
    return new Targets(bind$0040.Coverage, TargetSetting_KmuApproach_ShortTerm_ambitionScope1and2ByStartYear(shortTermStart) / (YearModule_durationExcl(shortTermStart, TargetSetting_KmuApproach_longTermStartYear) - 1), bind$0040.LongTermAmbition);
}

export function TargetSetting_KmuApproach_scope3(coverage, ambition) {
    return new Targets(new TermValuesGeneric$2(coverage, TargetSetting_AbsoluteContraction_scope3.Coverage.Long), ambition, TargetSetting_AbsoluteContraction_scope3.LongTermAmbition);
}

export function TargetSetting_getRangeInclFor(submissionYear, tt, tl, item) {
    switch (tt) {
        case 0:
            switch (item.tag) {
                case 1:
                    return [new Year(2015), new Year(2028)];
                case 2:
                    return [YearModule_step(5, tl.Short), new Year(2040)];
                case 3:
                    return [YearModule_step(5, tl.Long), new Year(2050)];
                default:
                    return [new Year(2021), new Year(2022)];
            }
        case 1:
            switch (item.tag) {
                case 1:
                    return [new Year(2020), new Year(2023)];
                case 2:
                    return TimeLineModule_offsetBy(7, 12, tl.Short);
                case 3:
                    return [YearModule_step(5, tl.Long), new Year(2050)];
                default:
                    return TargetSetting_baseYearRange(submissionYear);
            }
        case 2:
            switch (item.tag) {
                case 1:
                    return [new Year(2018), new Year(2022)];
                case 2: {
                    const endYear = TargetSetting_KmuApproach_ShortTerm_endYear;
                    return [endYear, endYear];
                }
                case 3:
                    return [YearModule_step(5, tl.Long), new Year(2050)];
                default:
                    return [new Year(2021), new Year(2022)];
            }
        default:
            throw new Error("Not implemented.");
    }
}

export function TargetSetting_updateTimeLine(targetType, tl, item, newValue) {
    switch (item.tag) {
        case 2: {
            const newLong_1 = YearModule_next(newValue);
            return new TimeLine(tl.Short, newLong_1, max(compare, YearModule_next(YearModule_step(5, newLong_1)), tl.Rest));
        }
        case 3:
            return new TimeLine(tl.Short, tl.Long, YearModule_next(newValue));
        case 0:
            throw new Error("This must never happen.");
        default:
            return new TimeLine(newValue, (targetType === 2) ? tl.Long : TimeLineModule_adjustLong(newValue, tl.Long), tl.Rest);
    }
}

export function TargetSetting_PhysicalIntensity_targetTypeFromSector(_arg) {
    switch (_arg.tag) {
        case 3:
            return 1;
        default:
            return 3;
    }
}

export const TargetSetting_PhysicalIntensity_longTermEndLimitEnergySectorScopes1and2 = new Year(2040);

