import { createElement } from "react";
import { map, ofArray } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { mapIndexed } from "../fable_modules/fable-library-js.4.16.0/Array.js";
import { split } from "../fable_modules/fable-library-js.4.16.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { int32ToString, equals, createObj, defaultOf } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { fixAndParseFloat, allowedIntChars, allowedFloatChars } from "./NumberInput.fs.js";
import { Float_render, Float_renderAsInt } from "../UIHelpers.fs.js";
import { map as map_1, empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.16.0/Seq.js";
import { value as value_14 } from "../fable_modules/fable-library-js.4.16.0/Option.js";
import { parse } from "../fable_modules/fable-library-js.4.16.0/Int32.js";
import { Record } from "../fable_modules/fable-library-js.4.16.0/Types.js";
import { YearModule_next, YearModule_rangeExclToSeq, Year, Year_$reflection } from "../../Shared/BaseTypes/Year.fs.js";
import { record_type, option_type } from "../fable_modules/fable-library-js.4.16.0/Reflection.js";

function Tooltip_renderParagraph(s) {
    let matchResult;
    if (s === defaultOf()) {
        matchResult = 0;
    }
    else if (s === "") {
        matchResult = 0;
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return createElement("div", {
                className: "gap",
            });
        default: {
            const children = ofArray(mapIndexed((idx, s_2) => {
                if ((idx % 2) === 0) {
                    return s_2;
                }
                else {
                    return createElement("em", {
                        children: [s_2],
                    });
                }
            }, split(s, ["*"], void 0, 0)));
            return createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
        }
    }
}

export function Tooltip_render(paragraphs) {
    let elems_1, elems, children;
    return createElement("div", createObj(ofArray([["className", "tooltip"], (elems_1 = [createElement("i", createObj(ofArray([["className", "fa fa-info-circle tooltip"], (elems = [(children = map(Tooltip_renderParagraph, paragraphs), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export const Tooltip_none = createElement("div", {
    className: "tooltip",
});

function NumberInput_allowOnlyFloatKeys(ev) {
    if (allowedFloatChars.indexOf(ev.key) === -1) {
        ev.preventDefault();
    }
}

export function NumberInput_allowOnlyIntKeys(ev) {
    if (allowedIntChars.indexOf(ev.key) === -1) {
        ev.preventDefault();
    }
}

export function NumberInput_renderFloat(className, numSpec_, numSpec__1, value, dispatch) {
    const numSpec_1 = [numSpec_, numSpec__1];
    const after = numSpec_1[1] | 0;
    let patternInput;
    switch (after) {
        case 0: {
            patternInput = [(ev) => {
                NumberInput_allowOnlyIntKeys(ev);
            }, Float_renderAsInt];
            break;
        }
        case 1: {
            patternInput = [(ev_1) => {
                NumberInput_allowOnlyFloatKeys(ev_1);
            }, Float_render];
            break;
        }
        default:
            throw new Error("Not implemented");
    }
    return createElement("input", createObj(toList(delay(() => append((className != null) ? singleton(["className", value_14(className)]) : empty(), delay(() => append(singleton(["onKeyPress", patternInput[0]]), delay(() => append(singleton(["onChange", (ev_2) => {
        dispatch(fixAndParseFloat(numSpec_1[0], numSpec_1[1], ev_2.target.value));
    }]), delay(() => {
        let value_5;
        return singleton((value_5 = patternInput[1](value), ["ref", (e) => {
            if (!(e == null) && !equals(e.value, value_5)) {
                e.value = value_5;
            }
        }]));
    }))))))))));
}

export function EnumDropDown_render(value, valueNamePairs, dispatch) {
    let value_6, elems;
    return createElement("select", createObj(ofArray([(value_6 = int32ToString(value), ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_6)) {
            e.value = value_6;
        }
    }]), ["onChange", (ev) => {
        dispatch(parse(ev.target.value, 511, false, 32));
    }], (elems = map((tupledArg) => createElement("option", {
        value: tupledArg[0],
        children: tupledArg[1],
    }), valueNamePairs), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export class YearDropDown_Config extends Record {
    constructor(Start, EndIncl, None, Selected) {
        super();
        this.Start = Start;
        this.EndIncl = EndIncl;
        this.None = None;
        this.Selected = Selected;
    }
}

export function YearDropDown_Config_$reflection() {
    return record_type("AccelerateGreen.WebUI.Controls.YearDropDown.Config", [], YearDropDown_Config, () => [["Start", Year_$reflection()], ["EndIncl", Year_$reflection()], ["None", option_type(Year_$reflection())], ["Selected", Year_$reflection()]]);
}

export function YearDropDown_buildConfig(_arg, none, selected) {
    return new YearDropDown_Config(_arg[0], _arg[1], none, selected);
}

/**
 * Build a dropdown for year selection.
 */
export function YearDropDown_render(config, dispatch) {
    let value_9, elems;
    return createElement("select", createObj(ofArray([(value_9 = int32ToString(config.Selected.fields[0]), ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_9)) {
            e.value = value_9;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Year(parse(ev.target.value, 511, false, 32)));
    }], (elems = delay(() => append((config.None != null) ? singleton(createElement("option", {
        value: value_14(config.None).fields[0],
        children: "keines",
    })) : empty(), delay(() => map_1((_arg) => {
        const yr = _arg.fields[0] | 0;
        return createElement("option", {
            value: yr,
            children: yr,
        });
    }, YearModule_rangeExclToSeq(config.Start, YearModule_next(config.EndIncl)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

