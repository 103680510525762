import { Record, Union } from "../../Client/fable_modules/fable-library-js.4.16.0/Types.js";
import { float64_type, record_type, union_type } from "../../Client/fable_modules/fable-library-js.4.16.0/Reflection.js";
import { TermValuesGeneric$2_$reflection } from "./Emissions.fs.js";
import { ScopeSelectionValues$1_$reflection } from "./Scope.fs.js";

export class Sector extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["EnergyProduction", "SeaTransportation", "Flag", "Others"];
    }
}

export function Sector_$reflection() {
    return union_type("AccelerateGreen.NetZeroScenario.DbModel.Sector", [], Sector, () => [[], [], [], []]);
}

export class TargetTypeValues$1 extends Record {
    constructor(PotentialApproach, AbsoluteContraction, KmuApproach, PhysicalIntensity) {
        super();
        this.PotentialApproach = PotentialApproach;
        this.AbsoluteContraction = AbsoluteContraction;
        this.KmuApproach = KmuApproach;
        this.PhysicalIntensity = PhysicalIntensity;
    }
}

export function TargetTypeValues$1_$reflection(gen0) {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.TargetTypeValues`1", [gen0], TargetTypeValues$1, () => [["PotentialApproach", gen0], ["AbsoluteContraction", gen0], ["KmuApproach", gen0], ["PhysicalIntensity", gen0]]);
}

export function TargetTypeValues_get(targetType, values) {
    switch (targetType) {
        case 0:
            return values.PotentialApproach;
        case 1:
            return values.AbsoluteContraction;
        case 2:
            return values.KmuApproach;
        case 3:
            return values.PhysicalIntensity;
        default:
            throw new Error("Unknown target type.");
    }
}

export function TargetTypeValues_set(targetType, newValue, values) {
    switch (targetType) {
        case 0:
            return new TargetTypeValues$1(newValue, values.AbsoluteContraction, values.KmuApproach, values.PhysicalIntensity);
        case 1:
            return new TargetTypeValues$1(values.PotentialApproach, newValue, values.KmuApproach, values.PhysicalIntensity);
        case 2:
            return new TargetTypeValues$1(values.PotentialApproach, values.AbsoluteContraction, newValue, values.PhysicalIntensity);
        case 3:
            return new TargetTypeValues$1(values.PotentialApproach, values.AbsoluteContraction, values.KmuApproach, newValue);
        default:
            throw new Error("Unknown target type.");
    }
}

export function TargetTypeValues_updateAll(update, values) {
    return new TargetTypeValues$1(update(values.PotentialApproach), update(values.AbsoluteContraction), update(values.KmuApproach), update(values.PhysicalIntensity));
}

export class PotentialApproachTargets extends Record {
    constructor(Coverage, ShortTermAmbitions, LongTermAmbition) {
        super();
        this.Coverage = Coverage;
        this.ShortTermAmbitions = ShortTermAmbitions;
        this.LongTermAmbition = LongTermAmbition;
    }
}

export function PotentialApproachTargets_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.PotentialApproachTargets", [], PotentialApproachTargets, () => [["Coverage", TermValuesGeneric$2_$reflection(float64_type, float64_type)], ["ShortTermAmbitions", ScopeSelectionValues$1_$reflection(float64_type)], ["LongTermAmbition", float64_type]]);
}

export class KmuApproachTargets extends Record {
    constructor(Coverage, Ambition) {
        super();
        this.Coverage = Coverage;
        this.Ambition = Ambition;
    }
}

export function KmuApproachTargets_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.KmuApproachTargets", [], KmuApproachTargets, () => [["Coverage", float64_type], ["Ambition", float64_type]]);
}

export class UserParameters extends Record {
    constructor(PotentialApproach, KmuApproach) {
        super();
        this.PotentialApproach = PotentialApproach;
        this.KmuApproach = KmuApproach;
    }
}

export function UserParameters_$reflection() {
    return record_type("AccelerateGreen.NetZeroScenario.DbModel.UserParameters", [], UserParameters, () => [["PotentialApproach", PotentialApproachTargets_$reflection()], ["KmuApproach", KmuApproachTargets_$reflection()]]);
}

