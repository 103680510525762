import { Msg, StateStorage } from "../Base/Msg.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { initialState } from "../Base/InitialState.fs.js";
import { State } from "../Base/State.fs.js";
import { Cmd_map, Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { render as render_4, update as update_1 } from "../Panels/PlanPanel.fs.js";
import { importEmissionSourcesFile } from "../DataExchange/Import.fs.js";
import { exportReductionPathFile } from "../DataExchange/Export.fs.js";
import { render as render_1, update as update_2 } from "../Panels/Storage.fs.js";
import { render as render_6, update as update_3 } from "./ServerStatus.fs.js";
import { render as render_5, update as update_4 } from "./StatusArea.fs.js";
import { render as render_2, update as update_5 } from "../Panels/TargetPanel.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import logo_white from "../style/logo-white.svg";
import { render as render_3 } from "../Panels/ChartPanel.fs.js";

export const initCmd = singleton((dispatch) => {
    dispatch(new Msg(9, [new StateStorage(2, [])]));
});

export function init() {
    return [initialState, initCmd];
}

export function update(backend, msg, state) {
    switch (msg.tag) {
        case 5:
            return [new State(state.Storage, state.IsServerReachable, state.TargetType, state.ScopeSelection, state.UserParameters, state.UserData, msg.fields[0], state.FocusedEmissionSource, state.StatusArea), Cmd_none()];
        case 6:
            return update_1(msg.fields[0], msg.fields[1], state);
        case 7:
            return importEmissionSourcesFile(backend, msg.fields[0], state);
        case 8:
            return exportReductionPathFile(backend, state);
        case 9:
            return update_2(backend, msg.fields[0], state);
        case 10:
        case 11:
            return update_3(backend, msg, state);
        case 12: {
            const patternInput = update_4(msg.fields[0], state.StatusArea);
            return [new State(state.Storage, state.IsServerReachable, state.TargetType, state.ScopeSelection, state.UserParameters, state.UserData, state.ShowAllScopes, state.FocusedEmissionSource, patternInput[0]), Cmd_map((Item) => (new Msg(12, [Item])), patternInput[1])];
        }
        default:
            return update_5(msg, state);
    }
}

export function render(version, state, dispatch) {
    let elems_2, elems_1, elems, value_13;
    const children = ofArray([createElement("div", createObj(ofArray([["className", "grid-container"], (elems_2 = [createElement("div", createObj(ofArray([["className", "head"], (elems_1 = [createElement("div", createObj(ofArray([["className", "title"], (elems = [createElement("h1", {
        title: "Version " + version,
        children: "Net-Zero-Scenario Tool",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), render_1(state.Storage, (arg) => {
        dispatch(new Msg(9, [arg]));
    }), createElement("img", createObj(ofArray([["id", "Logo"], (value_13 = logo_white, ["src", value_13])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), render_2(state, dispatch), render_3(state, dispatch), render_4(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), render_5(state.StatusArea, (arg_1) => {
        dispatch(new Msg(12, [arg_1]));
    }), render_6(state, dispatch)]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

