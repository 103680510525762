import { singleton } from "../fable_modules/fable-library-js.4.16.0/AsyncBuilder.js";
import { ByteArrayExtensions_SaveFileAs_Z4C1C8351 } from "../fable_modules/Fable.Remoting.Client.7.32.0/Extensions.fs.js";
import { buildChartData } from "../Panels/ChartPanel.fs.js";
import { Cmd_OfAsyncWith_attempt } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library-js.4.16.0/Async.js";
import { map } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { map as map_1, toList } from "../fable_modules/fable-library-js.4.16.0/Seq.js";
import { ReductionPathExport, ReductionPathColumn } from "../../Shared/ServerApi.fs.js";
import { Msg } from "../Base/Msg.fs.js";

const xlsxMimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

function downloadFile(backend, export$) {
    return singleton.Delay(() => singleton.Bind(backend.downloadReductionPathFile(export$), (_arg) => {
        ByteArrayExtensions_SaveFileAs_Z4C1C8351(_arg, "Diagrammdaten.xlsx", xlsxMimeType);
        return singleton.Zero();
    }));
}

export function exportReductionPathFile(backend, state) {
    const patternInput = buildChartData(state);
    return [state, Cmd_OfAsyncWith_attempt((computation) => {
        startImmediate(computation);
    }, (export$_1) => downloadFile(backend, export$_1), new ReductionPathExport(map((_arg) => _arg.Name, patternInput[1]), toList(map_1((c) => (new ReductionPathColumn(c.Year, c.Target, toList(c.Emissions))), patternInput[0]))), (Item) => (new Msg(10, [Item])))];
}

