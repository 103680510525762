import { ScopeEmissions, TermValuesGeneric$2, EmissionSource, EmissionSourceIdModule_create } from "../../Shared/BaseTypes/Emissions.fs.js";
import { empty } from "../fable_modules/fable-library-js.4.16.0/Map.js";
import { compare } from "../fable_modules/fable-library-js.4.16.0/Util.js";
import { TargetSetting_currentYear, TargetSetting_baseYearRange } from "../../Shared/Database/TargetSetting.fs.js";
import { Year } from "../../Shared/BaseTypes/Year.fs.js";
import { TimeLine } from "../../Shared/BaseTypes/TimeLine.fs.js";
import { empty as empty_1, singleton } from "../fable_modules/fable-library-js.4.16.0/List.js";
import { State, StatusArea_initialState, Storage } from "./State.fs.js";
import { ScopeValues$1, ScopeSelectionValues$1 } from "../../Shared/BaseTypes/Scope.fs.js";
import { TargetTypeValues$1, UserParameters, KmuApproachTargets, PotentialApproachTargets } from "../../Shared/BaseTypes/Targets.fs.js";
import { UserData } from "../../Shared/Database/UserData.fs.js";

function createEmissionSource(name) {
    return new EmissionSource(EmissionSourceIdModule_create(), name, 3, 0, empty({
        Compare: compare,
    }), "");
}

export function newEmissionSource() {
    return createEmissionSource("Neue Emissionsquelle");
}

export function restEmissionSource() {
    return createEmissionSource("Restemissionen");
}

const defaultTimeLine = new TimeLine(TargetSetting_baseYearRange(TargetSetting_currentYear)[0], new Year(2031), new Year(2041));

const scopeEmissions = new ScopeEmissions(0, singleton(restEmissionSource()), new TermValuesGeneric$2(0, 0));

export const initialState = new State(new Storage("Unbenannt", void 0, empty_1(), void 0), true, 0, 0, new UserParameters(new PotentialApproachTargets(new TermValuesGeneric$2(100, 100), new ScopeSelectionValues$1(0, 0, 0, 0), 100), new KmuApproachTargets(100, 0)), new UserData(new TargetTypeValues$1(defaultTimeLine, defaultTimeLine, defaultTimeLine, defaultTimeLine), new Year(2021), new ScopeValues$1(scopeEmissions, scopeEmissions, scopeEmissions)), false, void 0, StatusArea_initialState);

