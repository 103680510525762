
export const Percent_hundred = 100;

export const Percent_hundredInt = 100;

export const Percent_oneThird = Percent_hundred / 3;

export const Percent_twoThirds = 2 * Percent_oneThird;

/**
 * Convert int<pc> to fraction value as float.
 * Example: "fromIntToFraction 5<pc> = 0.05"
 */
export function Percent_fromIntToFraction(percentage) {
    return percentage / Percent_hundred;
}

/**
 * Calculate difference between 100% and the given percentage.
 * Example: "complement 25.0<pc> = 75.0<pc>".
 */
export function Percent_complement(percentage) {
    return Percent_hundred - percentage;
}

